import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { GridRight } from '../../Framework/Layouts/Grid';
import Text from '../../Framework/Components/Text';
import TextDate from '../../Framework/Components/TextDate';
import style from '../../../scss/components/form.module.scss';
import buttonStyle from '../../../scss/components/buttons/button.module.scss';

const ReportsFormRightGrid = ({ entity, resource }) => {
    const { t } = useTranslation();
    console.log(entity, resource);

    if (!entity) return null;

    let reportableEntity = null;
    let reportableButtonLabel = null;
    switch (entity.type) {
        case 'review':
            reportableEntity = 'reviews';
            reportableButtonLabel = t('reports.go_to_review');
            break;
        case 'product':
            reportableEntity = 'products';
            reportableButtonLabel = t('reports.go_to_product');
            break;
        case 'comment':
            reportableEntity = 'comments';
            reportableButtonLabel = t('reports.go_to_comment');
            break;
        case 'product-offer':
            reportableEntity = 'product-offers';
            reportableButtonLabel = t('reports.go_to_product_offer');
            break;
        default:
            break;
    }

    return (
        <GridRight>
            <div className="flex flex-col gap-4 p-8 text-2xl">
                <Text
                    name="id"
                    includeLabel
                    entity={entity}
                    resource={resource}
                />
                <Text
                    name="created_by.username"
                    includeLabel
                    isNested
                    entity={entity}
                    resource={resource}
                />
                {entity.type === 'review' && (
                    <Text
                        name="reportable.description"
                        includeLabel
                        isNested
                        entity={entity}
                        resource={resource}
                    />
                )}
                {entity.type === 'product' && (
                    <Text
                        name="reportable.name"
                        includeLabel
                        isNested
                        entity={entity}
                        resource={resource}
                    />
                )}
                {entity.type === 'comment' && (
                    <Text
                        name="reportable.comment"
                        includeLabel
                        isNested
                        entity={entity}
                        resource={resource}
                    />
                )}
                {entity.type === 'product-offer' && (
                    <Text
                        name="reportable.name"
                        includeLabel
                        isNested
                        entity={entity}
                        resource={resource}
                    />
                )}
                <div className={style.formGroup}>
                    <a
                        href={`/${reportableEntity}/${entity.reportable.id}/show`}
                        target="_blank"
                        rel="noreferrer"
                        className={buttonStyle.basic}
                    >
                        {reportableButtonLabel}
                    </a>
                </div>
                <Text
                    name="reportable.id"
                    includeLabel
                    isNested
                    entity={entity}
                    resource={resource}
                />
                <TextDate
                    name="created_at"
                    includeLabel
                    entity={entity}
                    resource={resource}
                />
                <Text
                    name="type"
                    localized
                    customResource="reports.type_key"
                    includeLabel
                    entity={entity}
                    resource={resource}
                />
            </div>
        </GridRight>
    );
};

export default ReportsFormRightGrid;

ReportsFormRightGrid.propTypes = {
    entity: PropTypes.object.isRequired,
    resource: PropTypes.string.isRequired,
};
