export default {
    reports: 'Reports',
    resource: 'Report',

    id: 'ID',
    product_id: 'Product ID',
    product_name: 'Product name',
    username: 'Username',
    type: 'Type',
    created_at: 'Created at',

    // Types of reports
    'type_key.product': 'Product',
    'type_key.product-offer': 'Marketplace',

    // Detail
    'product.id': 'Product ID',
    'product.name': 'Product name',
    'created_by.username': 'Created by',
    description: 'Description',
    categories: 'Reported issues',
    'reportable.name': 'Reported entity',
    'reportable.id': 'Reported entity ID',
    'reportable.description': 'Reported entity description',
    'reportable.comment': 'Reported entity comment',

    go_to_product: 'Go to product',
    go_to_review: 'Go to review',
    go_to_comment: 'Go to comment',
    go_to_product_offer: 'Go to product offer',
};
