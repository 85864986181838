export default {
    categories: 'Categories',
    resource: 'Category',

    id: 'ID',
    name: 'Name',
    slug: 'Slug',
    type: 'Type',
    description: 'Description',
    parent: 'Parent category',
    is_archived: 'Archived',
    delete: 'Delete',

    'categories-ingredient': 'Ingredient functions',
    'categories-product': 'Product Categories',
    'categories-article': 'Article categories',
    'categories-skintype': 'Skin types',
    'categories-skinconcern': 'Skin concerns',
    'categories-hairproblem': 'Hair problems',
    'categories-frequency': 'Frequencies',
    'categories-age': 'Ages',
    'categories-consistency': 'Consistencies',

    // Types in edit form
    article: 'article',
    ingredient: 'ingredient',
    product: 'product',
    skintype: 'skin type',
    skinconcern: 'skin problem',
    hairproblem: 'hair problem',
    frequency: 'Frequencies',
    age: 'Ages',
    consistency: 'Consistencies',
};
