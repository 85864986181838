import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

// ROUTES
import { useNavigate } from 'react-router-dom';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Button from './Buttons/Button';
import Modal from './Modal';

// STYLES
import style from '../../../scss/components/list.module.scss';
import { MessageContext } from '../Context/MessageContext';

const DEFAULT_DELETE_STATUS = {
    error: false,
    message: '',
};

const DeleteEntityButton = ({
    service,
    resource,
    entity,
    historyBackAfterDelete,
}) => {
    const { t } = useTranslation();
    const [deleteModal, showDeleteModal] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(DEFAULT_DELETE_STATUS);
    const { pushMessage } = useContext(MessageContext);
    const navigate = useNavigate();

    const openDeleteModal = () => showDeleteModal(true);
    const closeDeleteModal = () => showDeleteModal(false);
    const handleDelete = () => {
        service
            .remove(resource, entity.id)
            .then(() => {
                setDeleteStatus(DEFAULT_DELETE_STATUS);
                closeDeleteModal();
                pushMessage({ title: t('application.delete_success') });
                setTimeout(() => {
                    if (historyBackAfterDelete) {
                        // go back to the previous page
                        window.history.back();
                    } else {
                        navigate(`/${resource}`);
                    }
                }, 2000);
            })
            .catch((error) => {
                if (error.response) {
                    setDeleteStatus({
                        error: true,
                        message: error.response.data.message,
                    });
                } else {
                    setDeleteStatus({
                        error: true,
                        message: 'application.delete_error',
                    });
                }
            });
    };

    return (
        <div className="flex w-full justify-center">
            <Button
                name={t(`delete_${resource}`)}
                icon={faTrash}
                click={openDeleteModal}
                classNames="flex items-center justify-center font-semibold gap-4 bg-red py-5 px-10 rounded-lg text-white text-2xl bg-opacity-90 hover:bg-opacity-100 transition-colors"
            />

            <Modal
                contentLabel={t(`application.confirm_delete_title`)}
                isOpen={deleteModal}
                onRequestClose={closeDeleteModal}
            >
                <p>{t(`application.delete_${resource}_confirm`)}</p>

                {!deleteStatus.error && (
                    <Button name="delete" click={handleDelete} />
                )}

                {deleteStatus.error && (
                    <div className={style.errorMessage}>
                        {t(`application.${deleteStatus.message}`, {
                            resource: t(`application.${resource}`),
                        })}
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default DeleteEntityButton;

DeleteEntityButton.propTypes = {
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of resource
     */
    resource: PropTypes.string.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of historyBackAfterDelete
     */
    historyBackAfterDelete: PropTypes.bool,
};

DeleteEntityButton.defaultProps = {
    historyBackAfterDelete: false,
};
