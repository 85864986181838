import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

// ICONS
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';

// TRANSLATIONS
import { useTranslation } from 'react-i18next';

import useUpdateEffect from '../../../Hooks/useUpdateEffect';
import MediaService from '../../../Services/MediaService';

// COMPONENTS
import Flex from '../../Layouts/Flex';
import Button from '../Buttons/Button';
import Field from '../Form/Field';
import ImagePlaceholder from './ImagePlaceholder';

// STYLES
import form from '../../../../scss/components/form.module.scss';
import style from '../../../../scss/components/image.module.scss';

const getArrayOfImages = (data) => {
    if (data == null) return [];
    if (Array.isArray(data)) return data;
    if (typeof data === 'object') return [data];
    return [];
};

const Image = ({
    entity,
    onChange,
    errors,
    isDetachable = false,
    limit,
    ...props
}) => {
    const { name, resource } = props;
    const input = useRef(null);

    const { t } = useTranslation();

    const [medias, setMedias] = useState(getArrayOfImages(entity[name]));
    const pickImage = () => input.current.click();

    const upload = (e) => {
        MediaService.upload(e.target.files[0], resource).then(({ data }) => {
            const newMedias = medias ? [...medias, data] : [data];
            setMedias(() => newMedias);
        });
    };

    const detachImage = (mediaId) => {
        setMedias((old) => old.filter((media) => media.id !== mediaId));
    };

    useUpdateEffect(() => {
        onChange({
            [name]: medias,
        });
    }, [medias]);

    return (
        <div className={form.formGroup}>
            <Field {...props}>
                {(hasError, attributes, isChanged) => {
                    return (
                        <div
                            id="image-wrapper"
                            className={`
                                ${form.fieldInput}
                                ${hasError ? form.isInvalid : ''}
                                ${isChanged ? form.changedInput : ''}
                            `}
                        >
                            <input
                                type="file"
                                name={name}
                                ref={input}
                                onChange={upload}
                                style={{ display: 'none' }}
                                accept={'image/*'}
                            />

                            {medias && medias.length > 0 ? (
                                <div>
                                    {medias.map((media) => (
                                        <div
                                            key={media.id}
                                            className={style.image}
                                        >
                                            <img
                                                id={media.id}
                                                className={style.coverImage}
                                                src={`${
                                                    import.meta.env
                                                        .VITE_IMAGE_URL
                                                }/${media.path}`}
                                                alt=""
                                            />

                                            {isDetachable && (
                                                <Button
                                                    classNames={style.resetBtn}
                                                    name="reset"
                                                    text={t(
                                                        'application.reset',
                                                    )}
                                                    icon={faTrash}
                                                    click={() =>
                                                        detachImage(media.id)
                                                    }
                                                    type="button"
                                                    unlabeled
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <ImagePlaceholder />
                            )}
                            <Flex classNames={style.imageBtnWrapper}>
                                {medias && medias.length < limit && (
                                    <Button
                                        classNames={style.uploadBtn}
                                        name="image-upload"
                                        text={t('application.imageUpload')}
                                        icon={faUpload}
                                        click={pickImage}
                                        type="button"
                                    />
                                )}
                            </Flex>
                        </div>
                    );
                }}
            </Field>
        </div>
    );
};

Image.propTypes = {
    /**
     * Type of name
     */
    name: PropTypes.string.isRequired,
    /**
     * Type of entity
     */
    resource: PropTypes.object.isRequired,
    /**
     * Entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of onChange
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Type of errors
     */
    errors: PropTypes.array,
    /**
     * Type of isDetachable
     */
    isDetachable: PropTypes.bool,
    /**
     * Type of limit
     */
    limit: PropTypes.number,
};

Image.defaultProps = {
    errors: [],
    isDetachable: false,
    limit: 1,
};

export default Image;
