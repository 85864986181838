const getIdFromProp = (entity, propertyName) => {
    const property = entity[propertyName];

    if (property === null) return undefined;

    if (Array.isArray(property)) {
        return property.length > 0 ? property[0].id : null;
    }

    if (typeof property === 'object') {
        return property.hasOwnProperty('id') ? property.id : property;
    }

    return property;
};

const getMultipleIdsFromProp = (entity, propertyName) => {
    const property = entity[propertyName];
    if (property == null) return null;
    return typeof property === 'object' && property.hasOwnProperty('id')
        ? [property.id]
        : property.map((p) => p.id);
};

/*
const getIdsObjectFromImages = (entity) => {
    const { images } = entity;
    const imagesObject = {};
    images.forEach((image, index) => {
        imagesObject[index] = image.id;
    });
    return imagesObject;
};
*/

const getFirstItemFromArray = (entity, propertyName) => {
    const property = entity[propertyName];
    if (property == null) return null;
    if (!Array.isArray(property)) return property;
    if (property.length === 0) return null;

    return property[0];
};

const convertEntityObject = (resource, oldEntity) => {
    const entity = { ...oldEntity };

    if (resource === 'users') {
        // Axios's merge causing RangeError: Maximum call stack size exceeded
        // entity.skin_type = getIdFromProp(entity, 'skin_type');
        // entity.skin_concern = getIdFromProp(entity, 'skin_concern');
        // entity.hair_problem = getIdFromProp(entity, 'hair_problem');
        entity.image = getFirstItemFromArray(entity, 'image');

        delete entity.skin_type;
        delete entity.skin_concern;
        delete entity.hair_problem;
        delete entity.wishlist;
        delete entity.user_tags;
    }

    if (resource === 'products') {
        entity.images = getMultipleIdsFromProp(entity, 'images');
        entity.tags = getMultipleIdsFromProp(entity, 'tags');
        if (typeof entity.eans === 'string') {
            entity.eans = entity.eans
                .split('\n')
                .map((ean) => ean.trim())
                .filter((ean) => ean !== '');
        }
    }

    if (
        resource === 'brands' ||
        resource === 'authors' ||
        resource === 'articles' ||
        resource === 'brand-change-requests'
    ) {
        entity.image = getFirstItemFromArray(entity, 'image');
    }

    if (resource === 'product-change-requests') {
        entity.primary_category = getIdFromProp(entity, 'primary_category');
        entity.brand = getIdFromProp(entity, 'brand');
        entity.images = getMultipleIdsFromProp(entity, 'images');
        entity.tags = getMultipleIdsFromProp(entity, 'tags');
        if (typeof entity.eans === 'string') {
            entity.eans = entity.eans
                .split('\n')
                .map((ean) => ean.trim())
                .filter((ean) => ean !== '');
        }
    }

    if (resource === 'projects') {
        entity.image_id = getIdFromProp(entity, 'image');
        entity.product_id = getIdFromProp(entity, 'product');
        if (entity.image_id == null) {
            entity.image = undefined;
        }
    }

    if (resource === 'ingredients') {
        if (typeof entity.aliases === 'string') {
            entity.aliases = entity.aliases
                ?.split('\n')
                .map((alias) => alias.trim())
                .map((alias) => ({ name: alias }))
                .filter((alias) => alias.name !== '');
        }
    }

    return entity;
};

export default convertEntityObject;
