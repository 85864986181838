import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';

import {
    faChevronDown,
    faSquareCheck,
    faUserSlash,
} from '@fortawesome/free-solid-svg-icons';

import { faSquareMinus } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../Context/MessageContext';
import ExportAction from './ExportAction';

const API_ACTIONS = {
    confirm: {
        endpoint: 'confirm',
        messageSuccess: 'application.users_confirm_success',
        messageError: 'application.users_confirm_error',
    },
    revoke: {
        endpoint: 'revoke',
        messageSuccess: 'application.users_unconfirm_success',
        messageError: 'application.users_unconfirm_error',
    },
    remove: {
        endpoint: 'remove',
        messageSuccess: 'application.users_delete_success',
        messageError: 'application.users_delete_error',
    },
};

const UsersActions = ({
    service,
    resource,
    entity: projectEntity,
    selectedUsers,
    callSignal,
}) => {
    const { t } = useTranslation();
    const { pushMessage } = useContext(MessageContext);

    const users = selectedUsers.map((user) => user.id);

    const handleAction = (action) => {
        service
            .store(`projects/${projectEntity.id}/${action.endpoint}`, {
                users,
            })
            .then(() => {
                pushMessage({ title: t(action.messageSuccess) });
            })
            .catch(() => {
                pushMessage({ title: t(action.messageError), type: 'error' });
            })
            .finally(() => {
                callSignal();
            });
    };

    const handleConfirmUsers = () => handleAction(API_ACTIONS.confirm);
    const handleUnconfirmUsers = () => handleAction(API_ACTIONS.revoke);
    const handleDeleteUsers = () => handleAction(API_ACTIONS.remove);

    const options = [
        {
            name: t(`${resource}.action_confirm_users_button`),
            action: handleConfirmUsers,
            icon: faSquareCheck,
        },
        {
            name: t(`${resource}.action_revoke_users_button`),
            action: handleUnconfirmUsers,
            icon: faSquareMinus,
        },
        {
            name: t(`${resource}.action_delete_users_button`),
            action: handleDeleteUsers,
            icon: faUserSlash,
        },
    ];

    return (
        <div className="flex w-full justify-between items-center flex-wrap gap-4">
            <Menu as="div" className="grow relative">
                <Menu.Button
                    className="rounded-md text-light-snow px-6 py-3 text-2xl bg-dark-berry bg-opacity-90 hover:bg-opacity-100 flex items-center gap-3 transition-colors disabled:bg-opacity-30 disabled:cursor-not-allowed"
                    disabled={selectedUsers.length === 0}
                >
                    {t(`${resource}.bulk_actions`, {
                        count: selectedUsers.length,
                    })}
                    <FontAwesomeIcon icon={faChevronDown} />
                </Menu.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute gap-2 left-0 z-10 p-4 bg-light-snow mt-2 origin-top-left rounded-md bg-white shadow-lg">
                        {options.map((item) => (
                            <Menu.Item key={item.name}>
                                <button
                                    type="button"
                                    onClick={item.action}
                                    className="flex gap-6 w-full items-center text-left px-6 py-3 rounded-md text-xl hover:bg-light-yoghurt/40 transition-colors"
                                >
                                    <FontAwesomeIcon
                                        icon={item.icon}
                                        className="text-2xl"
                                    />
                                    {item.name}
                                </button>
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>

            <ExportAction
                entity={projectEntity}
                resource={resource}
                service={service}
            />
        </div>
    );
};

export default UsersActions;

UsersActions.propTypes = {
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of resource
     */
    resource: PropTypes.string.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of selectedUsers
     */
    selectedUsers: PropTypes.array.isRequired,
    /**
     * Type of callSignal
     */
    callSignal: PropTypes.func.isRequired,
};
