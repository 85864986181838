import PropTypes from 'prop-types';
import React from 'react';
import ProductUrlText from '../Products/ProductUrlText';

const ReportProductUrlText = ({ entity }) => {
    return <ProductUrlText entity={entity.product} />;
};

export default ReportProductUrlText;

ReportProductUrlText.propTypes = {
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
};
