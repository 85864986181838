import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../Framework/Components/Form/TextInput';

const SponsoredUrl = (props) => {
    const { entity } = props;
    if (!entity) return null;
    if (!entity.is_sponsored) return null;

    return (
        <div>
            <TextInput name="sponsored_url" {...props} />
        </div>
    );
};

SponsoredUrl.propTypes = {
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
};

export default SponsoredUrl;
