export default {
    comments: 'Comments',
    resource: 'Comment',
    comment: 'Comment',
    id: 'ID',
    'author.username': 'Username',
    created_at: 'Added',
    'offer.name': 'Offer name',
    'product.brand.title': 'Brand',
    like_count: 'Like count',
    is_approved: 'Is approved',
    description: 'Description',
    rate: 'Rate',
    flag_count: 'Flag count',
    updated_at: 'Updated at',

    delete: 'Delete',
    loading: 'Loading...',
};
