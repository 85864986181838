import { useState } from 'react';

const DEFAULT_PER_PAGE_COUNT = 20;
const usePagination = ({
    totalItemsCount,
    itemsPerPage = DEFAULT_PER_PAGE_COUNT,
}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [totalItems, setTotalItems] = useState(totalItemsCount);
    const totalPages = totalItems ? Math.ceil(totalItems / itemsPerPage) : 0;

    const updateTotalItems = (totalItems) => {
        setTotalItems(() => totalItems);
    };

    const changePage = ({ selected }) => {
        setCurrentPage(selected);
    };

    return {
        currentPage: currentPage + 1,
        currentPageIndex: currentPage,
        totalPages,
        totalItems,
        itemsPerPage,
        changePage,
        updateTotalItems,
    };
};

export default usePagination;
