import React from 'react';

import Form from '../../Framework/Components/Form/Form';
import Grid, { GridLeft, GridRight } from '../../Framework/Layouts/Grid';
import Text from '../../Framework/Components/Text';
import ReportProductUrlText from '../Reports/ReportProductUrlText';
import TextDate from '../../Framework/Components/TextDate';
import DeleteEntityButton from '../../Framework/Components/DeleteEntityButton';
import TextArea from '../../Framework/Components/Form/TextArea';

const CommentsForm = (props) => {
    return (
        <Form {...props}>
            <Grid classNames="mb-16">
                <GridLeft>
                    <div className="flex flex-col text-2xl gap-8 p-8">
                        <TextArea rows="10" name="comment" />
                        <TextDate name="created_at" includeLabel />
                        <TextDate name="updated_at" includeLabel />
                    </div>
                </GridLeft>

                <GridRight>
                    <div className="flex flex-col gap-4 p-8 text-2xl">
                        <Text name="product.name" includeLabel isNested />
                        <ReportProductUrlText />
                        <Text name="user.username" includeLabel isNested />
                    </div>
                </GridRight>
            </Grid>

            <DeleteEntityButton {...props} />
        </Form>
    );
};

export default CommentsForm;
