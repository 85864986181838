import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const Edit = ({ resource, entity, name, parseHtml }) => {
    const textToShowIsString = typeof entity[name] === 'string';
    const textToShow =
        textToShowIsString && parseHtml ? parse(entity[name]) : entity[name];
    return <Link to={`/${resource}/${entity.id}/show`}>{textToShow}</Link>;
};

export default Edit;

Edit.defaultProps = {
    parseHtml: false,
};

Edit.propTypes = {
    /**
     * Type of resource
     */
    resource: PropTypes.string.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    /**
     * Type of entity
     */
    name: PropTypes.string.isRequired,
    /**
     * Type of parseHtml
     */
    parseHtml: PropTypes.bool,
};
