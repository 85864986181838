import React from 'react';
import PropTypes from 'prop-types';
import TextArea from './TextArea';

const AliasTextArea = ({ entity, onChange, ...props }) => {
    const readableAliases = Array.isArray(entity.aliases)
        ? entity.aliases.map((alias) => alias.name).join('\n')
        : entity.aliases;

    const readableEntity = {
        ...entity,
        aliases: readableAliases,
    };
    return (
        <TextArea
            entity={readableEntity}
            onChange={onChange}
            resource="ingredients"
            rows={10}
            name="aliases"
            {...props}
        />
    );
};

export default AliasTextArea;

AliasTextArea.propTypes = {
    /**
     * Type of onChange
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
};
