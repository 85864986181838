export default {
    tags: 'Tags',
    resource: 'Label',
    id: 'ID',
    name: 'Name',
    slug: 'Slug',
    description: 'Description',
    is_highlighted: 'Highlighted',
    delete: 'Delete',
};
