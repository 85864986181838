import React from 'react';

// COMPONENTS
import BlockStyledEditor from '../../Framework/Components/Form/BlockStyledEditor';
import BrandInput from '../../Framework/Components/Form/BrandInput';
import CategoryInput from '../../Framework/Components/Form/CategoryInput';
import DateTime from '../../Framework/Components/Form/DateTime';
import Form from '../../Framework/Components/Form/Form';
import IngredientInput from '../../Framework/Components/Form/IngredientInput';
import TagInput from '../../Framework/Components/Form/TagInput';
import TextInput from '../../Framework/Components/Form/TextInput';
import Toggle from '../../Framework/Components/Form/Toggle';
import Image from '../../Framework/Components/Image/Image';
import TextResource from '../../Framework/Components/TextResource';
import Grid, { GridLeft, GridRight } from '../../Framework/Layouts/Grid';
import ProductReviews from '../../Framework/Pages/Product/Reviews';
import ProductUrlText from './ProductUrlText';
import EanTextArea from '../../Framework/Components/Form/EanTextArea';
import SponsoredUrl from './SponsoredUrl';

const ProductForm = (props) => {
    return (
        <Form {...props}>
            <TextResource
                name="product"
                className="text-3xl mb-8"
                isParagraph
                {...props}
            />

            <Grid classNames="mb-16">
                <GridLeft>
                    <ProductUrlText />
                    <TextInput name="name" />
                    <TextInput name="canonical_name" />
                    <TextInput name="price" />
                    <TextInput name="size" />
                    <TextInput name="where_to_find" />
                    <BlockStyledEditor name="description" fullFeatureSet />
                    <EanTextArea resourceName="products" />
                </GridLeft>
                <GridRight>
                    <Toggle name="is_active" />
                    <Toggle name="is_sponsored" />
                    <SponsoredUrl />
                    <Toggle name="is_hot" />
                    <Toggle name="is_manufactured" />
                    <Image name="images" limit={10} isDetachable />
                    <BrandInput name="brand" />
                    <CategoryInput
                        name="primary_category"
                        type="product"
                        isMultiple={false}
                    />
                    <CategoryInput name="product_categories" type="product" />
                    <CategoryInput name="skin_types" type="skintype" />
                    <CategoryInput name="skin_concerns" type="skinconcern" />
                    <CategoryInput name="hair_problems" type="hairproblem" />
                    <IngredientInput
                        name="ingredients"
                        isOrderable
                        isReversable
                    />
                    <TagInput name="tags" />
                    <DateTime name="published_at" readOnly />
                </GridRight>
            </Grid>

            <TextResource
                name="reviews"
                className="text-3xl mb-8"
                isParagraph
                {...props}
            />
            <ProductReviews {...props} />
        </Form>
    );
};

export default ProductForm;
