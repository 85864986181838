import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TextResource = ({ name, resource, isParagraph, ...props }) => {
    const { t } = useTranslation();
    const Component = isParagraph ? 'p' : 'span';

    return <Component {...props}>{t(`${resource}.${name}`)}</Component>;
};

export default TextResource;

TextResource.propTypes = {
    /**
     * Type of name
     */
    name: PropTypes.string.isRequired,
    /**
     * Type of resource
     */
    resource: PropTypes.string.isRequired,
    /**
     * Type of name
     */
    isParagraph: PropTypes.bool,
};

TextResource.defaultProps = {
    isParagraph: false,
};
