import PropTypes from 'prop-types';
import React from 'react';

import useAuth from '../../Hooks/useAuth';
import AuthenticationForm from '../../Pages/Authentication/AuthenticationForm';

export const AuthContext = React.createContext({ user: null });

export const AuthProvider = ({ children }) => {
    // STATES
    const { auth, logout } = useAuth();
    const { user, permissions, roles, authenticating, authorized } = auth;

    const hasPermission = (permission) => {
        return (
            user['super-admin'] ||
            permissions.filter(({ name }) => name === permission).length === 0
        );
    };

    if (authenticating) {
        return <div>Loading...</div>;
    }

    if (!authorized) {
        return <AuthenticationForm />;
    }

    return (
        <AuthContext.Provider
            value={{ user, permissions, roles, logout, hasPermission }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    /**
     * Type of resources
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
