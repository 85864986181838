import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import usePagination from '../../../Hooks/usePagination';
import Sublist from '../List/Sublist';
import paginationStyle from '../../../../scss/components/paginator/paginator.module.scss';
import TextResource from '../../Components/TextResource';
import Edit from '../../Components/Buttons/Edit';
import Text from '../../Components/Text';
import TextDate from '../../Components/TextDate';

const MarketplaceActivities = ({ service, entity: userEntity, userRole }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [activities, setActivities] = useState([]);
    const {
        currentPage,
        currentPageIndex,
        totalPages,
        changePage,
        updateTotalItems,
        itemsPerPage,
    } = usePagination({
        totalItemsCount: 0,
        itemsPerPage: 10,
    });

    const getActivities = () => {
        setIsLoading(true);
        service
            .get(`product-offers/${userEntity.id}/activity`, {
                per_page: itemsPerPage,
                page: currentPage,
                role: userRole,
            })
            .then((data) => {
                setActivities(data.data);
                updateTotalItems(data.meta.total);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getActivities();
    }, [currentPage]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center space-y-4 mb-16">
                <TextResource
                    resource="product-offers"
                    name="loading"
                    className="text-3xl mb-8"
                />
            </div>
        );
    }

    return (
        <div className="mb-8">
            <TextResource
                resource="users"
                name={userRole}
                className="text-2xl mb-8 font-medium"
                isParagraph
            />
            <Sublist
                service={service}
                resource="product-offers"
                entity={activities}
                onDelete={getActivities}
            >
                <Edit name="id" />
                <Text name="name" />
                <Text name="size" />
                <Text name="price" />
                <Text name="status" />
                <TextDate name="created_at" />
                <Text name="used" />
            </Sublist>
            <ReactPaginate
                forcePage={currentPageIndex}
                pageCount={totalPages}
                pageRangeDisplayed={4}
                marginPagesDisplayed={0}
                renderOnZeroPageCount={null}
                onPageChange={changePage}
                className={paginationStyle.paginationContainer}
                pageClassName={paginationStyle.paginationPage}
                activeClassName={paginationStyle.paginationPageActive}
                previousClassName={paginationStyle.paginationPage}
                nextClassName={paginationStyle.paginationPage}
                pageLinkClassName={paginationStyle.paginationPageLink}
                nextLinkClassName={paginationStyle.paginationPageLink}
                previousLinkClassName={paginationStyle.paginationPageLink}
                breakClassName="hidden"
                disabledClassName="opacity-40"
                previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
                nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
            />
        </div>
    );
};

export default MarketplaceActivities;

MarketplaceActivities.propTypes = {
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of userRole
     */
    userRole: PropTypes.oneOf(['seller', 'buyer']).isRequired,
};
