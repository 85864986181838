import { format } from 'date-fns';

function convertToApiDate(dateString) {
    if (!dateString) {
        return null;
    }
    const date = new Date(dateString);
    return format(date, "yyyy-MM-dd'T'HH:mm");
}

export default convertToApiDate;
