import PropTypes from 'prop-types';
import React from 'react';

// TRANSLATIONS
import { useTranslation } from 'react-i18next';

// COMPONENTS
import AutoComplete from './AutoComplete';
import OrderableAutoComplete from './OrderableAutoComplete';

const IngredientInput = ({
    entity,
    onChange,
    resource,
    service,
    allowNew,
    errors,
    isOrderable,
    isReversable,
    ...props
}) => {
    const { t } = useTranslation();

    if (isOrderable) {
        return (
            <OrderableAutoComplete
                entity={entity}
                onChange={onChange}
                resource={resource}
                service={service}
                name="ingredients"
                searchBy="name"
                reference="ingredients"
                placeholder={t('application.ingredients')}
                isMultiple
                isReversable={isReversable}
                errors={errors}
                allowNew={allowNew}
                {...props}
            />
        );
    }

    return (
        <AutoComplete
            entity={entity}
            onChange={onChange}
            resource={resource}
            service={service}
            name="ingredients"
            searchBy="name"
            reference="ingredients"
            placeholder={t('application.ingredients')}
            isMultiple
            errors={errors}
            allowNew={allowNew}
            {...props}
        />
    );
};

export default IngredientInput;

IngredientInput.propTypes = {
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of onChange
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Type of resource
     */
    resource: PropTypes.object.isRequired,
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of allowNew
     */
    allowNew: PropTypes.bool,
    /**
     * Type of errors
     */
    errors: PropTypes.object.isRequired,
    /**
     * Type of isOrderable
     */
    isOrderable: PropTypes.bool,
    /**
     * Type of isReversable
     */
    isReversable: PropTypes.bool,
};

IngredientInput.defaultProps = {
    allowNew: false,
    isOrderable: false,
    isReversable: false,
};
