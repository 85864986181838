export default {
    reviews: 'Reviews',
    resource: 'Review',

    id: 'ID',
    'user.username': 'Username',
    created_at: 'Added',
    'product.name': 'Product name',
    'product.brand.title': 'Brand',
    like_count: 'Like count',
    is_approved: 'Is approved',
    description: 'Description',
    rate: 'Rate',
    flag_count: 'Flag count',
    is_organic: 'Is organic',
    is_like_count_approved: 'Is like count approved',
    updated_at: 'Updated at',

    delete: 'Delete',
    loading: 'Loading...',

    // Edit Form Sections
    review_details: 'Review Details',
    transfer: 'Transfer this review to another product',
    product_name_label: 'Search by Product name',
    product_name_placeholder: 'Enter product name',
    product_id_label: 'Search by Product ID',
    product_id_placeholder: 'Enter product ID',
    transfer_or_label: 'or',
};
