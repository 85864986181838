import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../Context/MessageContext';

const ExportAction = ({ service, resource, entity: projectEntity }) => {
    const { t } = useTranslation();
    const { pushMessage } = useContext(MessageContext);

    const handleExport = () => {
        service
            .get(`${resource}/${projectEntity.id}/export`)
            .then((data) => {
                const blob = new Blob([data], {
                    type: 'text/csv;charset=utf-8',
                });
                FileSaver.saveAs(
                    blob,
                    `export_project_${projectEntity.id}.csv`,
                );
                pushMessage({ title: t('application.export_success') });
            })
            .catch(() => {
                pushMessage({
                    title: t('application.export_error'),
                    type: 'error',
                });
            });
    };

    return (
        <button
            type="button"
            onClick={handleExport}
            className="rounded-md text-light-snow px-6 py-3 text-2xl bg-dark-spinach/90 hover:bg-dark-spinach flex items-center gap-3 transition-colors"
        >
            <FontAwesomeIcon icon={faDownload} />
            {t(`${resource}.export`)}
        </button>
    );
};

export default ExportAction;

ExportAction.propTypes = {
    service: PropTypes.object.isRequired,
    resource: PropTypes.string.isRequired,
    entity: PropTypes.object.isRequired,
};
