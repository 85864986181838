import {
    faArrowRightFromBracket,
    faBabyCarriage,
    faBoxesStacked,
    faBriefcase,
    faBusinessTime,
    faCartFlatbed,
    faCartPlus,
    faCartShopping,
    faComment,
    faDroplet,
    faFlask,
    faFlaskVial,
    faGift,
    faHandDots,
    faHands,
    faMessage,
    faNewspaper,
    faPaste,
    faRepeat,
    faTags,
    faTriangleExclamation,
    faUser,
    faUserNurse,
    faUsers,
    faVialCircleCheck,
} from '@fortawesome/free-solid-svg-icons';

const navigationIcons = {
    users: faUser,
    authors: faUsers,
    articles: faNewspaper,
    tags: faTags,
    brands: faBriefcase,
    'brand-change-requests': faBusinessTime,
    products: faCartShopping,
    'product-offers': faCartFlatbed,
    'product-change-requests': faCartPlus,
    'ingredient-change-requests': faVialCircleCheck,
    ingredients: faFlask,
    reviews: faMessage,
    projects: faGift,
    reports: faTriangleExclamation,
    categories: faBoxesStacked,
    'categories-article': faPaste,
    'categories-ingredient': faFlaskVial,
    'categories-product': faCartFlatbed,
    'categories-skintype': faHands,
    'categories-skinconcern': faHandDots,
    'categories-hairproblem': faUserNurse,
    'categories-frequency': faRepeat,
    'categories-age': faBabyCarriage,
    'categories-consistency': faDroplet,
    logout: faArrowRightFromBracket,
    comments: faComment,
};

export default navigationIcons;
