const getNestedValue = (obj, key) => {
    const keys = key.split('.');
    let extendedObj = obj;
    for (let i = 0; i < keys.length; i += 1) {
        if (extendedObj && extendedObj.hasOwnProperty(keys[i])) {
            extendedObj = extendedObj[keys[i]];
        } else {
            return extendedObj ?? undefined;
        }
    }

    return extendedObj ?? undefined;
};

export default getNestedValue;
