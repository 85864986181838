import React from 'react';
// PAGE
import Admin from './Framework/Pages/Admin';
// RESOURCES
import Resource from './Framework/Resource';
// ROUTES
import { logoutRoutes } from './Routes/web';
// PAGES
import ArticleForm from './Pages/Articles/ArticleForm';
import ArticleList from './Pages/Articles/ArticleList';
import AuthorForm from './Pages/Authors/AuthorForm';
import AuthorList from './Pages/Authors/AuthorList';
import BrandChangeRequestForm from './Pages/BrandChangeRequests/BrandChangeRequestForm';
import BrandChangeRequestList from './Pages/BrandChangeRequests/BrandChangeRequestList';
import BrandForm from './Pages/Brands/BrandForm';
import BrandList from './Pages/Brands/BrandList';
import CategoryForm from './Pages/Categories/CategoryForm';
import AgeCategoryList from './Pages/Categories/Lists/AgeCategoryList';
import ArticleCategoryList from './Pages/Categories/Lists/ArticleCategoryList';
import FrequencyCategoryList from './Pages/Categories/Lists/FrequencyCategoryList';
import HairProblemCategoryList from './Pages/Categories/Lists/HairProblemCategoryList';
import IngredientCategoryList from './Pages/Categories/Lists/IngredientCategoryList';
import ProductCategoryList from './Pages/Categories/Lists/ProductCategoryList';
import SkinConcernCategoryList from './Pages/Categories/Lists/SkinConcernCategoryList';
import SkinTypeCategoryList from './Pages/Categories/Lists/SkinTypeCategoryList';
import IngredientChangeRequestForm from './Pages/IngredientChangeRequests/IngredientChangeRequestForm';
import IngredientChangeRequestList from './Pages/IngredientChangeRequests/IngredientChangeRequestList';
import IngredientForm from './Pages/Ingredients/IngredientForm';
import IngredientList from './Pages/Ingredients/IngredientList';
import ProductChangeRequestForm from './Pages/ProductChangeRequests/ProductChangeRequestForm';
import ProductChangeRequestList from './Pages/ProductChangeRequests/ProductChangeRequestList';
import ProductForm from './Pages/Products/ProductForm';
import ProductList from './Pages/Products/ProductList';
import ReportsForm from './Pages/Reports/ReportsForm';
import ReportsList from './Pages/Reports/ReportsList';
import ReviewForm from './Pages/Reviews/ReviewForm';
import ReviewList from './Pages/Reviews/ReviewList';
import TagForm from './Pages/Tags/TagForm';
import TagList from './Pages/Tags/TagList';
import TestItForm from './Pages/TestIt/TestItForm';
import TestItList from './Pages/TestIt/TestItList';
import UserForm from './Pages/Users/UserForm';
import UserList from './Pages/Users/UserList';
import CommentsForm from './Pages/Comments/CommentsForm';
import CommentsList from './Pages/Comments/CommentsList';
import ProductOfferList from './Pages/ProductOffers/ProductOfferList';
import ProductOfferForm from './Pages/ProductOffers/ProductOfferForm';
import ProductOfferCommentsForm from './Pages/ProductOfferComments/ProductOfferCommentsForm';

const Dashboard = () => {
    return (
        <Admin>
            <Resource name="users" list={UserList} form={UserForm} />
            <Resource name="authors" list={AuthorList} form={AuthorForm} />
            <Resource name="articles" list={ArticleList} form={ArticleForm} />
            <Resource name="brands" list={BrandList} form={BrandForm} />
            <Resource
                name="brand-change-requests"
                list={BrandChangeRequestList}
                approve={BrandChangeRequestForm}
            />
            <Resource name="tags" list={TagList} form={TagForm} />
            <Resource name="products" list={ProductList} form={ProductForm} />
            <Resource
                name="product-change-requests"
                list={ProductChangeRequestList}
                approve={ProductChangeRequestForm}
            />
            <Resource
                name="product-offers"
                list={ProductOfferList}
                form={ProductOfferForm}
            />
            <Resource
                name="ingredients"
                list={IngredientList}
                form={IngredientForm}
            />
            <Resource
                name="ingredient-change-requests"
                list={IngredientChangeRequestList}
                form={IngredientChangeRequestForm}
            />
            <Resource name="reviews" list={ReviewList} form={ReviewForm} />
            <Resource name="comments" list={CommentsList} form={CommentsForm} />
            <Resource
                name="product-offer-comments"
                form={ProductOfferCommentsForm}
            />
            <Resource name="projects" list={TestItList} form={TestItForm} />
            <Resource name="reports" list={ReportsList} form={ReportsForm} />
            <Resource name="categories" groupParent group="categories">
                <Resource
                    name="categories-article"
                    list={ArticleCategoryList}
                    form={CategoryForm}
                    group="categories"
                />
                <Resource
                    name="categories-product"
                    list={ProductCategoryList}
                    form={CategoryForm}
                    group="categories"
                />
                <Resource
                    name="categories-skintype"
                    list={SkinTypeCategoryList}
                    form={CategoryForm}
                    group="categories"
                />
                <Resource
                    name="categories-skinconcern"
                    list={SkinConcernCategoryList}
                    form={CategoryForm}
                    group="categories"
                />
                <Resource
                    name="categories-hairproblem"
                    list={HairProblemCategoryList}
                    form={CategoryForm}
                    group="categories"
                />
                <Resource
                    name="categories-ingredient"
                    list={IngredientCategoryList}
                    form={CategoryForm}
                    group="categories"
                />
                <Resource
                    name="categories-frequency"
                    list={FrequencyCategoryList}
                    form={CategoryForm}
                    group="categories"
                />
                <Resource
                    name="categories-age"
                    list={AgeCategoryList}
                    form={CategoryForm}
                    group="categories"
                />
                <Resource
                    name="categories-consistency"
                    form={CategoryForm}
                    group="categories"
                />
            </Resource>
            <Resource
                name="logout"
                routes={logoutRoutes}
                requiresPermission={false}
            />
        </Admin>
    );
};

export default Dashboard;
