import PropTypes from 'prop-types';
import { useState } from 'react';

const useTableSelection = ({ entities, onSelectedChange }) => {
    const [selected, setSelected] = useState([]);

    const changeSelection = (item) => {
        const newState = selected.some((el) => el.id === item.id)
            ? selected.filter((el) => el.id !== item.id)
            : [...selected, item];

        setSelected(newState);
        onSelectedChange(newState)?.();
    };

    const selectAll = () => {
        if (selected.length === entities.length) {
            setSelected([]);
            onSelectedChange([])?.();
        } else {
            setSelected(entities);
            onSelectedChange(entities)?.();
        }
    };

    return {
        selected,
        changeSelection,
        isSelected: (item) => selected.some((el) => el.id === item.id),
        selectAll,
    };
};

export default useTableSelection;

useTableSelection.propTypes = {
    entities: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelectedChange: PropTypes.func,
};

useTableSelection.defaultProps = {
    onSelectedChange: () => {},
};
