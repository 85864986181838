import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import application from './Translations/application';
import articles from './Translations/articles';
import authors from './Translations/authors';
import brandChangeRequests from './Translations/brand-change-requests';
import brands from './Translations/brands';
import categories from './Translations/categories';
import editor from './Translations/editor';
import ingredientChangeRequests from './Translations/ingredient-change-requests';
import ingredients from './Translations/ingredients';
import productChangeRequests from './Translations/product-change-requests';
import products from './Translations/products';
import projects from './Translations/projects';
import reports from './Translations/reports';
import reviews from './Translations/reviews';
import comments from './Translations/comments';
import tags from './Translations/tags';
import users from './Translations/users';
import productOffers from './Translations/product-offers';
import productOfferComments from './Translations/product-offer-comments';

const resources = {
    hu: {
        translation: {
            application,
            logout: application,
            users,
            tags,
            categories,
            'categories-ingredient': categories,
            'categories-product': categories,
            'categories-article': categories,
            'categories-skintype': categories,
            'categories-skinconcern': categories,
            'categories-hairproblem': categories,
            'categories-frequency': categories,
            'categories-age': categories,
            'categories-consistency': categories,
            authors,
            articles,
            brands,
            'brand-change-requests': brandChangeRequests,
            products,
            'product-offers': productOffers,
            'product-change-requests': productChangeRequests,
            ingredients,
            editor,
            reviews,
            comments,
            'product-offer-comments': productOfferComments,
            projects,
            'ingredient-change-requests': ingredientChangeRequests,
            reports,
        },
    },
};

i18n.use(initReactI18next).init({
    interpolation: { escapeValue: false },
    resources,
    lng: 'hu',
});

export default i18n;
