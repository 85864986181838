import React from 'react';

// Components
import ApproveForm from '../../Framework/Components/Form/ApproveForm';
import BlockStyledEditor from '../../Framework/Components/Form/BlockStyledEditor';
import BrandInput from '../../Framework/Components/Form/BrandInput';
import CategoryInput from '../../Framework/Components/Form/CategoryInput';
import IngredientInput from '../../Framework/Components/Form/IngredientInput';
import TagInput from '../../Framework/Components/Form/TagInput';
import TextInput from '../../Framework/Components/Form/TextInput';
import Image from '../../Framework/Components/Image/Image';
import Grid, { GridLeft, GridRight } from '../../Framework/Layouts/Grid';

// STYLES
import style from '../../../scss/components/form.module.scss';
import EanTextArea from '../../Framework/Components/Form/EanTextArea';

const ProductChangeRequestForm = (props) => {
    return (
        <ApproveForm creates="products" {...props}>
            <Grid>
                <GridLeft>
                    <TextInput className={style.changedInput} name="name" />
                    <TextInput name="canonical_name" />
                    <TextInput name="price" />
                    <TextInput name="size" />
                    <TextInput name="where_to_find" />
                    <BlockStyledEditor name="description" fullFeatureSet />
                </GridLeft>
                <GridRight>
                    <Image name="images" limit={10} isDetachable />
                    <BrandInput name="brand" />
                    <CategoryInput
                        name="primary_category"
                        type="product"
                        isMultiple={false}
                    />
                    <CategoryInput name="categories" type="product" />
                    <CategoryInput name="skin_types" type="skintype" />
                    <CategoryInput name="skin_concerns" type="skinconcern" />
                    <CategoryInput name="hair_problems" type="hairproblem" />
                    <IngredientInput name="ingredients" allowNew />
                    <TagInput name="tags" />
                    <EanTextArea resourceName="product-change-requests" />
                </GridRight>
            </Grid>
        </ApproveForm>
    );
};

export default ProductChangeRequestForm;
