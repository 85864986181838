import React from 'react';
import PropTypes from 'prop-types';
import TextArea from './TextArea';

const EanTextArea = ({ entity, onChange, resourceName, ...props }) => {
    const readableEans = Array.isArray(entity.eans)
        ? entity.eans.join('\n')
        : entity.eans;

    const readableEntity = {
        ...entity,
        eans: readableEans,
    };
    return (
        <TextArea
            entity={readableEntity}
            onChange={onChange}
            resource={resourceName}
            rows={10}
            name="eans"
            {...props}
        />
    );
};

export default EanTextArea;

EanTextArea.propTypes = {
    /**
     * Type of onChange
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of resourceName
     */
    resourceName: PropTypes.string.isRequired,
};
