export default {
    brands: 'Brands',
    resource: 'Brand',

    id: 'ID',
    title: 'Name',
    slug: 'Slug',
    url: 'URL',
    where_to_find: 'Location',
    description: 'Description',
    delete: 'Delete',

    featured: 'Recommended',
    image: 'Image',
};
