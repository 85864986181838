import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Sublist from '../../Framework/Pages/List/Sublist';
import Text from '../../Framework/Components/Text';
import Delete from '../../Framework/Components/Buttons/Delete';
import Edit from '../../Framework/Components/Buttons/Edit';

const ReviewReplies = ({ service, commentResource, entity: commentEntity }) => {
    const [comments, setComments] = useState(
        commentEntity.replies ?? commentEntity.comments ?? [],
    );

    const deleteCommentCallback = (id) => {
        setComments((c) => c.filter((comment) => comment.id !== id));
    };

    return (
        <div>
            <Sublist
                resource={commentResource}
                service={service}
                entity={comments}
                selectable={false}
                onDelete={deleteCommentCallback}
            >
                <Edit name="id" />
                <Text name="comment" />
                <Text name="created_at" />
                <Delete name="delete" list />
            </Sublist>
        </div>
    );
};

ReviewReplies.propTypes = {
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of resource
     */
    commentResource: PropTypes.string.isRequired,
};

export default ReviewReplies;
