import React from 'react';

import Text from '../../Framework/Components/Form/TextInput';
import Search from '../../Framework/Components/Search';

const UserSearch = (props) => (
    <Search {...props}>
        <Text name="username" />
        <Text name="email" />
        <Text name="activities.ip_address" />
        <Text name="uuid" />
    </Search>
);

export default UserSearch;
