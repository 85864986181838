export default {
    articles: 'Articles',
    resource: 'Article',

    id: 'ID',
    title: 'Title',
    lead: 'Lead',
    slug: 'Slug',
    body: 'Body',
    is_active: 'Active',
    is_sponsored: 'Sponsored',
    is_18_plus: 'over 18',
    published_at: 'Published',
    image: 'Image',

    tags: 'Tags',
    authors: 'Authors',
    categories: 'Categories',

    delete: 'Delete',
};
