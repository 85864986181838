import React from 'react';
import ArrayCheckboxes from '../../Framework/Components/ArrayCheckboxes';
import DeleteEntityButton from '../../Framework/Components/DeleteEntityButton';
import Form from '../../Framework/Components/Form/Form';
import Text from '../../Framework/Components/Text';
import Grid, { GridLeft } from '../../Framework/Layouts/Grid';
import ReportsFormRightGrid from './ReportsFormRightGrid';

const ReportsForm = (props) => {
    return (
        <Form {...props} immutable>
            <Grid classNames="mb-16">
                <GridLeft>
                    <div className="flex flex-col text-2xl gap-8 p-8">
                        <ArrayCheckboxes name="categories" includeLabel />
                        <Text name="description" includeLabel isParagraph />
                    </div>
                </GridLeft>
                <ReportsFormRightGrid {...props} />
            </Grid>

            <DeleteEntityButton {...props} />
        </Form>
    );
};

export default ReportsForm;
