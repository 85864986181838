import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import getNestedValue from '../../../Helpers/nestedValue';

const Age = ({
    entity,
    name,
    isParagraph = false,
    isNested = false,
    includeLabel = false,
    resource,
    ...props
}) => {
    const { t } = useTranslation();
    if (!entity) return null;

    const Paragraph = isParagraph ? 'p' : 'span';
    let content = isNested ? getNestedValue(entity, name) : entity?.[name];
    content = new Date().getFullYear() - new Date(content).getFullYear();

    if (includeLabel) {
        return (
            <div className="flex flex-col">
                <span>{t(`${resource}.${name}`)}</span>
                <Paragraph {...props}>{content ?? '-'}</Paragraph>
            </div>
        );
    }

    return <Paragraph {...props}>{content ?? '-'}</Paragraph>;
};

export default Age;

Age.propTypes = {
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of name
     */
    name: PropTypes.string.isRequired,
    /**
     * Type of name
     */
    isParagraph: PropTypes.bool,
    /**
     * Type of name
     */
    isNested: PropTypes.bool,
    /**
     * Type of includeLabel
     */
    includeLabel: PropTypes.bool,
    /**
     * Type of resource
     */
    resource: PropTypes.string.isRequired,
};

Age.defaultProps = {
    isParagraph: false,
    isNested: false,
    includeLabel: false,
};
