import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TextResource from '../../Framework/Components/TextResource';
import Sublist from '../../Framework/Pages/List/Sublist';
import Text from '../../Framework/Components/Text';
import Select from '../../Framework/Components/Buttons/Select';
import Modal from '../../Framework/Components/Modal';
import Button from '../../Framework/Components/Buttons/Button';
import styleList from '../../../scss/components/list.module.scss';
import style from '../../../scss/components/form.module.scss';
import useDebounce from '../../Hooks/useDebounce';
import { MessageContext } from '../../Framework/Context/MessageContext';

const DEFAULT_SEARCH_OPTIONS = {
    productName: '',
    productId: null,
};

const ProductOfferProductTransfer = ({
    service,
    resource,
    entity: commentEntity,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchOptions, setSearchOptions] = useState(DEFAULT_SEARCH_OPTIONS);
    const [availableProducts, setAvailableProducts] = useState([]);
    const [marked, setMarked] = useState(null);
    const { pushMessage } = useContext(MessageContext);

    const [selectError, setSelectError] = useState(false);

    const markEntityForTransfer = (entity) => setMarked(entity);

    const clearMark = () => {
        setMarked(null);
        setSelectError(false);
    };
    const handleInputChange = (e) => {
        setSearchOptions(() => ({
            ...DEFAULT_SEARCH_OPTIONS,
            [e.target.name]: e.target.value,
        }));
    };

    const refetchProducts = useDebounce(500, () => {
        if (searchOptions.productId !== null) {
            service
                .find('products', searchOptions.productId)
                .then((data) => {
                    setAvailableProducts([data.data]);
                })
                .catch(() => {
                    setAvailableProducts([]);
                });
        } else {
            service
                .get('products', {
                    name: searchOptions.productName,
                    per_page: 10,
                })
                .then((data) => {
                    setAvailableProducts(data.data);
                })
                .catch(() => {
                    setAvailableProducts([]);
                });
        }
    });
    useEffect(() => {
        if (
            searchOptions.productName.length > 0 ||
            searchOptions.productId !== null
        ) {
            refetchProducts();
        }
    }, [searchOptions.productId, searchOptions.productName]);

    const copyProductOffer = () => {
        service
            .post('product-offers', `${commentEntity.id}/duplicate`, {
                product_id: marked.id,
            })
            .then((data) => {
                clearMark();
                setSearchOptions(DEFAULT_SEARCH_OPTIONS);
                pushMessage('success', 'application.transfer_success');
                setTimeout(() => {
                    if (data?.id != null) {
                        navigate(`/product-offers/${data.id}/show`);
                        navigate(0);
                    }
                }, 2000);
            })
            .catch(() => {
                setSelectError('transfer_error');
                pushMessage({
                    title: t('application.transfer_error'),
                    type: 'error',
                });
            });
    };

    const moveProductOffer = () => {
        service
            .post('product-offers', `${commentEntity.id}/move`, {
                product_id: marked.id,
            })
            .then(() => {
                clearMark();
                setSearchOptions(DEFAULT_SEARCH_OPTIONS);
                pushMessage('success', 'application.copy_success');
                setTimeout(() => {
                    navigate(0);
                }, 2000);
            })
            .catch(() => {
                setSelectError('copy_error');
                pushMessage({
                    title: t('application.copy_error'),
                    type: 'error',
                });
            });
    };

    return (
        <div className="w-full bg-light-snow text-2xl">
            <div className="flex gap-8 items-center px-8 pt-8 pb-4">
                <div className="flex flex-col w-full">
                    <label htmlFor="productName">
                        {t(`${resource}.product_name_label`)}
                        <input
                            id="productName"
                            name="productName"
                            className={style.formTextInput}
                            type="text"
                            onChange={handleInputChange}
                            value={searchOptions.productName}
                            autoComplete="off"
                            placeholder={t(
                                `${resource}.product_name_placeholder`,
                            )}
                        />
                    </label>
                </div>

                <TextResource name="transfer_or_label" resource={resource} />

                <div className="flex flex-col w-full">
                    <label htmlFor="productId">
                        {t(`${resource}.product_id_label`)}
                    </label>
                    <input
                        id="productId"
                        name="productId"
                        className={style.formTextInput}
                        type="number"
                        min="0"
                        onChange={handleInputChange}
                        value={searchOptions.productId ?? ''}
                        autoComplete="off"
                        placeholder={t(`${resource}.product_id_placeholder`)}
                    />
                </div>
            </div>

            <Sublist
                service={service}
                resource="products"
                entity={availableProducts}
                overrideDelete
                handleDelete={markEntityForTransfer}
            >
                <Text name="id" />
                <Text name="name" />
                <Select name="select" />
            </Sublist>

            <Modal
                contentLabel={t(`application.confirm_selection`)}
                isOpen={!!marked}
                onRequestClose={clearMark}
            >
                {marked && (
                    <p>
                        {t('application.transfer_product_offer', {
                            product: marked.name || `ID ${marked.id}`,
                        })}
                    </p>
                )}
                {!selectError && (
                    <>
                        <Button
                            name="copy_confirm_button"
                            click={copyProductOffer}
                        />
                        <Button
                            click={moveProductOffer}
                            name="move_confirm_button"
                        />
                    </>
                )}
                {selectError && (
                    <div className={styleList.errorMessage}>
                        {t(`application.${selectError}`, {
                            resource: t(`application.${resource}`),
                        })}
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default ProductOfferProductTransfer;

ProductOfferProductTransfer.propTypes = {
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of resource
     */
    resource: PropTypes.string.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
};
