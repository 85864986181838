import React from 'react';
import Edit from '../../Framework/Components/Buttons/Edit';
import Text from '../../Framework/Components/Text';
import TextDate from '../../Framework/Components/TextDate';
import List from '../../Framework/Pages/List/List';

const TestItList = (props) => {
    return (
        <List {...props}>
            <Text name="id" />
            <Edit name="name" />
            <Text name="type" localized customResource="projects.type_key" />
            <Text name="status" />
            <Text name="users" isCount />
            <TextDate name="sign_up_start_date" />
            <TextDate name="sign_up_end_date" />
        </List>
    );
};

export default TestItList;
