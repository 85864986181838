import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import getFields from '../../../Helpers/getters';
import Button from '../../Components/Buttons/Button';
import Modal from '../../Components/Modal';
import Table from '../../Components/Table';

import style from '../../../../scss/components/list.module.scss';

const Sublist = ({
    service,
    resource,
    entity,
    onDelete,
    overrideDelete,
    handleDelete,
    children,
    selectable,
    onSelectedChange,
}) => {
    const { t } = useTranslation();
    const [fields] = useState(getFields(children));

    // Delete
    const [marked, setMarked] = useState(null);
    const [deleteError, setDeleteError] = useState(false);
    const markEntityForDeletion = (entity) => setMarked(entity);
    const clearMark = () => {
        setMarked(null);
        setDeleteError(false);
    };

    const removeEntity = (entity) =>
        service
            .remove(resource, entity.id)
            .then(() => setMarked(null))
            .catch((error) => {
                if (error.response) {
                    setDeleteError(error.response.data.message);
                }
            })
            .finally(() => onDelete(entity?.id));

    return (
        <>
            <Table
                entities={entity}
                fields={fields}
                data={{ resource, service }}
                remove={overrideDelete ? handleDelete : markEntityForDeletion}
                selectable={selectable}
                onSelectedChange={onSelectedChange}
            />

            <Modal
                contentLabel="Törlés megerősítése"
                isOpen={!!marked}
                onRequestClose={clearMark}
            >
                {marked && (
                    <p>
                        Biztosan törlöd a következőt:{' '}
                        <strong>
                            {marked.name ||
                                marked.title ||
                                marked.search_term ||
                                marked.username ||
                                `ID ${marked.id}`}
                        </strong>
                        ?
                    </p>
                )}
                {!deleteError && (
                    <Button name="delete" click={() => removeEntity(marked)}>
                        Törlés
                    </Button>
                )}
                {deleteError && (
                    <div className={style.errorMessage}>
                        {t(`application.${deleteError}`, {
                            resource: t(`application.${resource}`),
                        })}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default Sublist;

Sublist.propTypes = {
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of resource
     */
    resource: PropTypes.string.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of onDelete
     */
    onDelete: PropTypes.func,
    /**
     * Type of overrideDelete
     */
    overrideDelete: PropTypes.bool,
    /**
     * Type of handleDelete
     */
    handleDelete: PropTypes.func,
    /**
     * Type of selectable
     */
    selectable: PropTypes.bool,
    /**
     * Type of onSelectedChange
     */
    onSelectedChange: PropTypes.func,
    /**
     * Type of children
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

Sublist.defaultProps = {
    onDelete: () => {},
    overrideDelete: false,
    handleDelete: () => {},
    selectable: false,
    onSelectedChange: () => {},
};
