export default {
    authors: 'Authors',
    resource: 'Author',

    id: 'ID',
    title: 'Title',
    name: 'Name',
    slug: 'Slug',
    email: 'Email',
    description: 'Description',
    delete: 'Delete',
    image: 'Image',
};
