import PropTypes from 'prop-types';
import React from 'react';

// TRANSLATIONS
import { useTranslation } from 'react-i18next';

// COMPONENTS
import getNestedValue from '../../../Helpers/nestedValue';
import AutoComplete from './AutoComplete';

const ProductInput = ({
    entity,
    onChange,
    resource,
    service,
    errors,
    excludedIdProp,
    disableFallback,
    ...props
}) => {
    const { t } = useTranslation();
    const withoutId = excludedIdProp
        ? getNestedValue(entity, excludedIdProp)
        : null;

    return (
        <AutoComplete
            entity={entity}
            onChange={onChange}
            resource={resource}
            service={service}
            name="product"
            searchBy="name"
            reference="products"
            placeholder={t('application.products')}
            isMultiple={false}
            errors={errors}
            withoutId={withoutId}
            disableFallback={disableFallback}
            {...props}
        />
    );
};

export default ProductInput;

ProductInput.propTypes = {
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of onChange
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Type of resource
     */
    resource: PropTypes.object.isRequired,
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of errors
     */
    errors: PropTypes.object.isRequired,
    /**
     * Type of excludedIdProp
     */
    excludedIdProp: PropTypes.string,
    /**
     * Type of disableFallback
     */
    disableFallback: PropTypes.bool,
};

ProductInput.defaultProps = {
    excludedIdProp: null,
    disableFallback: false,
};
