import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Button from '../../Framework/Components/Buttons/Button';

import style from '../../../scss/components/buttons/button.module.scss';
import Modal from '../../Framework/Components/Modal';
import { MessageContext } from '../../Framework/Context/MessageContext';

const BanUserButton = ({ service, entity }) => {
    const { t } = useTranslation();
    const [marked, setMarked] = useState(false);
    const clearMark = () => setMarked(false);
    const { pushMessage } = useContext(MessageContext);

    const markUserForBan = () => setMarked(true);

    const banUser = () => {
        service
            .post('product-offers', `${entity.id}/ban`, {})
            .then((data) => {
                const bannedUntil = format(
                    new Date(data.banned_until),
                    'yyyy-MM-dd HH:mm:ss',
                );
                pushMessage({
                    title: t('application.ban_success', {
                        until: bannedUntil,
                    }),
                });
                clearMark();
            })
            .catch(() => {
                pushMessage({ title: t('application.ban_error') });
            });
    };

    const bannedUntil = format(
        new Date(entity?.banned_until),
        'yyyy-MM-dd HH:mm:ss',
    );

    return (
        <>
            <Button
                classNames={`${style.button} ${style.basic} mt-4`}
                name="ban_user"
                onClick={markUserForBan}
            />

            {entity.is_banned && (
                <div className="flex flex-col mt-8">
                    <p className="text-2xl text-red">
                        {t('users.banned', { date: bannedUntil })}
                    </p>
                </div>
            )}

            <Modal
                contentLabel={t(`application.confirm_selection`)}
                isOpen={!!marked}
                onRequestClose={clearMark}
            >
                <p>
                    {t('application.ban_user_confirm', {
                        product: marked.name || `ID ${marked.id}`,
                    })}
                </p>
                <Button name="ban_user_confirm_button" click={banUser} />
            </Modal>
        </>
    );
};

export default BanUserButton;
BanUserButton.propTypes = {
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
};
