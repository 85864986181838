import React from 'react';
import Search from '../../Framework/Components/Search';
import Text from '../../Framework/Components/Form/TextInput';

const ReviewSearch = (props) => {
    return (
        <Search {...props}>
            <Text name="product.name" isNested />
            <Text name="user.username" isNested />
        </Search>
    );
};

export default ReviewSearch;
