import Cookies from 'js-cookie';

const ONE_WEEK_IN_DAYS = 7;
export const ACCESS_TOKEN_KEY = '@kremmania/admin/accessToken';
export const ACCESS_TOKEN_REFRESH_TIME_KEY =
    '@kremmania/admin/accessTokenRefreshTime';

export const getAccessToken = async () => {
    const currentToken = Cookies.get(ACCESS_TOKEN_KEY);
    return currentToken;
};

export const setAccessCookie = (accessToken) => {
    if (!accessToken) {
        return;
    }
    Cookies.set(ACCESS_TOKEN_KEY, accessToken, {
        expires: ONE_WEEK_IN_DAYS,
        secure: process.env.NODE_ENV === 'production',
    });
    Cookies.set(
        ACCESS_TOKEN_REFRESH_TIME_KEY,
        `${new Date().getTime() + 30 * 60 * 1000}`,
        {
            expires: ONE_WEEK_IN_DAYS,
            secure: process.env.NODE_ENV === 'production',
        },
    );
};
export const removeAccessCookies = () => {
    Cookies.remove(ACCESS_TOKEN_KEY);
    Cookies.remove(ACCESS_TOKEN_REFRESH_TIME_KEY);
};
