import React from 'react';
import ProductOfferSearch from './ProductOfferSearch';
import List from '../../Framework/Pages/List/List';
import Text from '../../Framework/Components/Text';
import Edit from '../../Framework/Components/Buttons/Edit';
import Delete from '../../Framework/Components/Buttons/Delete';
import Boolean from '../../Framework/Components/Boolean';

const ProductOfferList = (props) => {
    return (
        <List search={ProductOfferSearch} {...props} deleteonly>
            <Text name="id" />
            <Edit name="name" />
            <Text name="price" />
            <Text name="size" />
            <Text name="status" />
            <Boolean name="is_sold" />
            <Text name="created_by.username" isNested />
            <Text name="bought_by.username" isNested />
            <Text name="created_at" />

            <Delete name="delete" list />
        </List>
    );
};

export default ProductOfferList;
