import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { AsyncTypeahead, TypeaheadInputMulti } from 'react-bootstrap-typeahead';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import useUpdateEffect from '../../../Hooks/useUpdateEffect';

// COMPONENTS
import Field from './Field';
import Token from './Token';

// STYLES
import style from '../../../../scss/components/form.module.scss';
import '../../../../scss/external/select.scss';
import Button from '../Buttons/Button';

const OrderableAutoComplete = ({
    onChange,
    entity,
    placeholder,
    searchBy,
    isMultiple = false,
    isReversable = false,
    ...props
}) => {
    const { name, service, reference, allowNew } = props;
    const { t } = useTranslation();

    const [state, setState] = useState({
        items: [],
        selected: entity[name]
            ? (Array.isArray(entity[name]) && entity[name]) || [entity[name]]
            : [],
        isLoading: false,
    });

    const update = (change) =>
        setState((oldState) => ({ ...oldState, ...change }));

    const onSearch = (query) => {
        update({ isLoading: true });

        service
            .autocomplete(reference, {
                [searchBy]: query,
                withoutId: entity.id,
            })
            .then((result) => {
                const items = [...result.data];

                update({ items, isLoading: false });
            });
    };

    const onMove = useCallback(
        (dragIndex, hoverIndex) => {
            console.log(state.selected, 'selected start');
            const item = state.selected[dragIndex];
            const newSelected = state.selected.slice();
            console.log(newSelected, 'first');
            newSelected.splice(dragIndex, 1);
            console.log(newSelected, 'sec');
            newSelected.splice(hoverIndex, 0, item);
            console.log(newSelected, 'third');

            setState((state) => ({ ...state, selected: newSelected }));
        },
        [state.selected],
    );

    const onSelectionChange = (selection) => {
        update({ selected: selection });
    };

    const handleReverse = () => {
        const newSelected = state.selected.toReversed();
        setState((state) => ({ ...state, selected: newSelected }));
    };

    useUpdateEffect(() => {
        if (isMultiple) {
            onChange({ [name]: state.selected });
        } else {
            onChange({ [name]: state.selected[0] || null });
        }
    }, [state.selected]);

    return (
        <div className={style.formGroup}>
            <Field {...props}>
                {(hasError, attributes, isChanged) => {
                    return (
                        <DndProvider backend={HTML5Backend}>
                            <AsyncTypeahead
                                id={`${entity.name}.${name}-select`}
                                className={`
                                ${style.autocomplete}
                                -${name}
                                ${isChanged ? style.changedInput : ''}
                            `}
                                isLoading={state.isLoading}
                                onSearch={onSearch}
                                promptText={placeholder}
                                minLength={3}
                                multiple={isMultiple}
                                labelKey={searchBy}
                                options={state.items}
                                selected={state.selected}
                                flip
                                emptyLabel={t('application.noResults')}
                                searchText={t('application.search')}
                                onChange={onSelectionChange}
                                inputProps={{
                                    id: attributes.id,
                                    className: `
                                    ${style.textArea}
                                    ${hasError ? style.isInvalid : ''}
                                `,
                                }}
                                allowNew={allowNew}
                                {...attributes}
                                renderInput={(inputProps, typeaheadProps) => (
                                    <TypeaheadInputMulti
                                        {...inputProps}
                                        selected={state.selected}
                                    >
                                        {state.selected.map((option, ind) => (
                                            <Token
                                                index={ind}
                                                key={option?.id}
                                                onMove={onMove}
                                                onRemove={
                                                    typeaheadProps.onRemove
                                                }
                                                option={option}
                                            >
                                                {option?.name ??
                                                    option?.title ??
                                                    'mo'}
                                            </Token>
                                        ))}
                                    </TypeaheadInputMulti>
                                )}
                            />
                        </DndProvider>
                    );
                }}
            </Field>

            {isReversable && (
                <Button
                    name="order_reverse"
                    click={handleReverse}
                    classNames="w-full p-4 bg-light-yoghurt rounded-lg mt-2"
                />
            )}
        </div>
    );
};

export default OrderableAutoComplete;

OrderableAutoComplete.propTypes = {
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of onChange
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Type of placeholder
     */
    placeholder: PropTypes.string.isRequired,
    /**
     * Type of searchBy
     */
    searchBy: PropTypes.string.isRequired,
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of reference
     */
    reference: PropTypes.string.isRequired,
    /**
     * Type of isMultiple
     */
    isMultiple: PropTypes.bool.isRequired,
    /**
     * Type of name
     */
    name: PropTypes.string.isRequired,
    /**
     * Type of allowNew
     */
    allowNew: PropTypes.bool,
    /**
     * Type of isReversable
     */
    isReversable: PropTypes.bool,
};

OrderableAutoComplete.defaultProps = {
    allowNew: false,
    isReversable: false,
};
