import PropTypes from 'prop-types';
import React from 'react';
// TRANSLATION
import { useTranslation } from 'react-i18next';
// STYLE
import style from '../../../scss/components/table.module.scss';
import useTableSelection from '../../Hooks/useTableSelection';

const Table = ({
    fields,
    entities,
    data,
    remove,
    selectable,
    onSelectedChange,
    showUnreadInBold,
}) => {
    const { resource, service } = data;
    const { t } = useTranslation();
    const { selected, changeSelection, isSelected, selectAll } =
        useTableSelection({
            entities,
            onSelectedChange,
        });

    return (
        <div className={style.tableWrapper}>
            <div className={style.tableResponsiveContainer}>
                <table className={style.inner}>
                    <thead className={style.header}>
                        <tr className={style.row}>
                            {selectable && (
                                <th className={style.title}>
                                    <input
                                        type="checkbox"
                                        checked={
                                            selected.length === entities.length
                                        }
                                        onChange={selectAll}
                                    />
                                </th>
                            )}
                            {fields.map(({ name }) => (
                                <th key={name} className={style.title}>
                                    {t(`${resource}.${name}`)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={style.body}>
                        {entities &&
                            entities.map((entity) => (
                                <tr
                                    key={entity.id}
                                    className={`${style.row} ${
                                        showUnreadInBold && !entity.is_read
                                            ? style.bold
                                            : ''
                                    }`}
                                >
                                    {selectable && (
                                        <td
                                            key={`select-${entity.id}`}
                                            className={style.column}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={isSelected(entity)}
                                                onChange={() =>
                                                    changeSelection(entity)
                                                }
                                            />
                                        </td>
                                    )}
                                    {fields.map(
                                        ({ component: Component, ...rest }) => (
                                            <td
                                                className={style.column}
                                                key={rest.name + entity.id}
                                            >
                                                <Component
                                                    service={service}
                                                    resource={resource}
                                                    entity={entity}
                                                    remove={remove}
                                                    {...rest}
                                                />
                                            </td>
                                        ),
                                    )}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Table;

Table.propTypes = {
    /**
     * Type of fields
     */
    fields: PropTypes.array.isRequired,
    /**
     * Type of entities
     */
    entities: PropTypes.array.isRequired,
    /**
     * Type of data
     */
    data: PropTypes.shape({
        resource: PropTypes.string.isRequired,
        service: PropTypes.object.isRequired,
    }).isRequired,
    /**
     * Type of remove
     */
    remove: PropTypes.func.isRequired,
    /**
     * Type of selectable
     */
    selectable: PropTypes.bool,
    /**
     * Type of onSelectedChange
     */
    onSelectedChange: PropTypes.func,
    /**
     * Type of showUnreadInBold
     */
    showUnreadInBold: PropTypes.bool,
};

Table.defaultProps = {
    selectable: false,
    onSelectedChange: () => {},
    showUnreadInBold: false,
};
