import PropTypes from 'prop-types';
import React from 'react';

// COMPONENTS
import EditChange from '../../Framework/Components/Buttons/EditChange';
import Text from '../../Framework/Components/Text';
import List from '../../Framework/Pages/List/List';

const IngredientChangeRequestList = (props) => {
    return (
        <List {...props} readonly>
            <Text name="id" />
            <EditChange name="name" />
        </List>
    );
};

export default IngredientChangeRequestList;

IngredientChangeRequestList.propTypes = {
    /**
     * Type of entity
     */
    entity: PropTypes.object,
};

IngredientChangeRequestList.defaultProps = {
    entity: {},
};
