export default {
    ingredients: 'Ingredients',
    resource: 'Component',

    id: 'ID',
    name: 'Name',
    slug: 'Slug',
    description: 'Description',
    is_top: 'Favorite',
    ewg_data: 'EWG data',
    ewg_score: 'EWG risk',
    ewg_score_max: 'EWG max risk',
    comedogen_index: 'Comedogen index',
    published_at: 'Published',
    categories: 'Functions',
    irritative_index: 'Irritative index',
    aliases: 'Ingredient Aliases',

    Robust: 'Very much',
    Good: 'Many',
    Fair: 'Medium',
    Limited: 'Limited',
    None: 'None',

    delete: 'Delete',
};
