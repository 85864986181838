import { useContext, useEffect, useState } from 'react';
import { HttpContext } from '../Framework/Context/HttpContext';

function useAuth() {
    const { http } = useContext(HttpContext);

    const [auth, setAuth] = useState({
        user: null,
        authenticating: true,
        roles: [],
        permissions: [],
        authorized: false,
    });

    useEffect(() => {
        setAuth((currentAuth) => ({
            ...currentAuth,
            authenticating: true,
        }));

        http.user()
            .then(({ data }) => {
                setAuth((currentAuth) => ({
                    ...currentAuth,
                    user: data,
                    authenticating: false,
                    authorized: true,
                }));
            })
            .catch((error) => {
                console.log(error);
                setAuth((currentAuth) => ({
                    ...currentAuth,
                    authorized: false,
                    authenticating: false,
                }));
            });
    }, []);

    const logout = () => {
        http.logout().then(() => {
            window.location.replace('/');
        });
    };

    return { auth, logout };
}

export default useAuth;
