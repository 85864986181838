import React, { useContext, useState } from 'react';
import BaseModal from 'react-modal';
import styleButton from '../../../scss/components/buttons/button.module.scss';
import style from '../../../scss/components/form.module.scss';
import styleModal from '../../../scss/components/modal.module.scss';
import Label from '../../Framework/Components/Form/Label';
import { HttpContext } from '../../Framework/Context/HttpContext';
import { setAccessCookie } from '../../Helpers/token';

const AuthenticationForm = () => {
    const { http } = useContext(HttpContext);
    const [error, setError] = useState(null);
    const [entity, setEntity] = useState({
        email: '',
        password: '',
    });

    const changeEmail = (e) => {
        setEntity((currentEntity) => ({
            ...currentEntity,
            email: e.target.value,
        }));
    };
    const changePassword = (e) => {
        setEntity((currentEntity) => ({
            ...currentEntity,
            password: e.target.value,
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        http.localLogin(entity)
            .then((data) => {
                const isAdmin = data.user.roles.find(
                    (role) => role.name === 'super-admin',
                );
                if (!isAdmin) {
                    setError('You are not authorized to access this page');
                    return;
                }
                setAccessCookie(data.access_token);
                window.location.reload();
            })
            .catch((error) => {
                setError(error.message);
            });
    };

    return (
        <div>
            <BaseModal
                ariaHideApp={false}
                isOpen
                className={styleModal.content}
                overlayClassName={styleModal.overlay}
            >
                <form onSubmit={onSubmit}>
                    <p className={`${style.error} ${style.label}`}>{error}</p>
                    <div className={style.formGroup}>
                        <Label
                            className={style.label}
                            to="email"
                            text="Email"
                        />
                        <input
                            className={style.fieldInput}
                            type="email"
                            onChange={changeEmail}
                            value={entity.email || ''}
                            autoComplete="off"
                        />
                    </div>
                    <div className={style.formGroup}>
                        <Label
                            className={style.label}
                            to="password"
                            text="Password"
                        />
                        <input
                            className={style.fieldInput}
                            type="password"
                            onChange={changePassword}
                            value={entity.password || ''}
                            autoComplete="off"
                        />
                    </div>
                    <div className="mt-6">
                        <button
                            type="submit"
                            className={`${styleButton.button} ${styleButton.basic}`}
                        >
                            Login
                        </button>
                    </div>
                </form>
            </BaseModal>
        </div>
    );
};

export default AuthenticationForm;
