import React from 'react';
import Boolean from '../../Framework/Components/Boolean';
import Edit from '../../Framework/Components/Buttons/Edit';
import Text from '../../Framework/Components/Text';
import TextDate from '../../Framework/Components/TextDate';
import List from '../../Framework/Pages/List/List';
import Delete from '../../Framework/Components/Buttons/Delete';
import ReviewSearch from './ReviewSearch';

const ReviewList = (props) => {
    return (
        <List {...props} showUnreadInBold search={ReviewSearch} deleteonly>
            <Edit name="id" resource="reviews" />
            <Text name="user.username" isNested />
            <TextDate name="created_at" />
            <Boolean name="is_approved" />
            <Text name="like_count" />
            <Text
                name="description"
                isParagraph
                className="max-w-2xl truncate"
            />
            <Text name="product.name" isNested />
            <Delete name="delete" list />
        </List>
    );
};

export default ReviewList;
