import React from 'react';

// Components
import Form from '../../Framework/Components/Form/Form';

import TextInput from '../../Framework/Components/Form/TextInput';
import Image from '../../Framework/Components/Image/Image';
import Text from '../../Framework/Components/Text';
import TextDate from '../../Framework/Components/TextDate';
import TextResource from '../../Framework/Components/TextResource';
import Grid, { GridLeft, GridRight } from '../../Framework/Layouts/Grid';
import UserReviews from '../../Framework/Pages/User/Reviews';
import BanUserButton from './BanUserButton';
import MarketplaceActivities from '../../Framework/Pages/User/MarketplaceActivities';
import UserRatings from '../../Framework/Pages/User/UserRatings';

const UserForm = (props) => {
    return (
        <Form {...props}>
            <TextResource
                name="profile"
                className="text-3xl mb-8"
                isParagraph
                {...props}
            />

            <Grid classNames="mb-16">
                <GridLeft>
                    <div className="grid grid-cols-1 md:grid-cols-2 text-2xl gap-8">
                        <div className="flex flex-col gap-4 md:border-r border-r-light-cloud p-8">
                            <Text name="id" includeLabel />
                            <Text name="uuid" includeLabel />
                            <Text name="firstname" includeLabel />
                            <Text name="lastname" includeLabel />
                            <Text name="gender" includeLabel />
                            <Text name="title" includeLabel />
                            <Text name="sso_id" includeLabel />
                        </div>

                        <div className="flex flex-col gap-4 p-8">
                            <Text name="birth_year" includeLabel />
                            <Text name="skin_type.name" isNested includeLabel />
                            <Text
                                name="skin_concern.name"
                                isNested
                                includeLabel
                            />
                            <Text
                                name="hair_problem.name"
                                isNested
                                includeLabel
                            />
                            <TextDate name="created_at" includeLabel />
                            <TextDate
                                name="filled_questionnaire_at"
                                includeLabel
                            />
                            <Text name="description" includeLabel />
                        </div>
                    </div>
                </GridLeft>
                <GridRight>
                    <TextInput name="email" />
                    <TextInput name="username" />
                    <TextInput name="points" />
                    <Image name="image" limit={1} isDetachable />
                    <BanUserButton />
                </GridRight>
            </Grid>

            <TextResource
                name="reviews"
                className="text-3xl mb-8"
                isParagraph
                {...props}
            />
            <UserReviews {...props} />

            <TextResource
                name="marketplace_activities"
                className="text-3xl mb-8 font-bold"
                isParagraph
                {...props}
            />

            <MarketplaceActivities userRole="seller" {...props} />
            <MarketplaceActivities userRole="buyer" {...props} />

            <TextResource
                name="user_ratings"
                className="text-3xl mb-8 font-bold"
                isParagraph
                {...props}
            />

            <UserRatings userRole="seller" {...props} />
            <UserRatings userRole="buyer" {...props} />
        </Form>
    );
};

export default UserForm;
