import React from 'react';

// Components
import { useTranslation } from 'react-i18next';
import DateTime from '../../Framework/Components/Form/DateTime';
import Form from '../../Framework/Components/Form/Form';
import ProductInput from '../../Framework/Components/Form/ProductInput';
import StaticDropDown from '../../Framework/Components/Form/StaticDropDown';
import TextArea from '../../Framework/Components/Form/TextArea';
import TextInput from '../../Framework/Components/Form/TextInput';
import Image from '../../Framework/Components/Image/Image';
import TextResource from '../../Framework/Components/TextResource';
import Grid, { GridLeft, GridRight } from '../../Framework/Layouts/Grid';
import TestItAdvanced from './TestItAdvanced';

export const TESTIT_PROJECTS_TYPES = {
    BIGGER: 'bigger',
    SMALLER: 'smaller',
    GIVE_AWAY: 'give-away',
};

const TestItForm = (props) => {
    const { t } = useTranslation();
    const typeOptions = [
        {
            id: TESTIT_PROJECTS_TYPES.BIGGER,
            label: t(
                `projects.type_key.${TESTIT_PROJECTS_TYPES.BIGGER}`,
                TESTIT_PROJECTS_TYPES.BIGGER,
            ),
        },
        {
            id: TESTIT_PROJECTS_TYPES.SMALLER,
            label: t(
                `projects.type_key.${TESTIT_PROJECTS_TYPES.SMALLER}`,
                TESTIT_PROJECTS_TYPES.SMALLER,
            ),
        },
        {
            id: TESTIT_PROJECTS_TYPES.GIVE_AWAY,
            label: t(
                `projects.type_key.${TESTIT_PROJECTS_TYPES.GIVE_AWAY}`,
                TESTIT_PROJECTS_TYPES.GIVE_AWAY,
            ),
        },
    ];

    const isAdvancedVisible = window.location.pathname.includes('/show');

    return (
        <Form {...props}>
            <TextResource
                name="information"
                className="text-3xl mb-8"
                isParagraph
                {...props}
            />

            <Grid classNames="mb-16">
                <GridLeft>
                    <TextInput name="name" showDescription />
                    <ProductInput
                        name="product"
                        excludedIdProp="product.id"
                        disableFallback
                        showDescription
                    />
                    <TextInput name="slug" />
                    <TextArea name="search_text" rows={2} showDescription />
                    <TextArea name="landing_text" rows={2} showDescription />
                    <TextArea name="description" rows={4} />
                </GridLeft>
                <GridRight>
                    <StaticDropDown
                        name="type"
                        staticOptions={typeOptions}
                        readonly={isAdvancedVisible}
                    />
                    <DateTime name="sign_up_start_date" />
                    <DateTime name="sign_up_end_date" />
                    <DateTime name="testing_start_date" />
                    <DateTime name="testing_end_date" />

                    <Image
                        name="image"
                        showDescription
                        limit={1}
                        isDetachable
                    />
                </GridRight>
            </Grid>

            {isAdvancedVisible && (
                <div>
                    <TextResource
                        name="advanced"
                        className="text-3xl mb-8"
                        isParagraph
                        {...props}
                    />

                    <TestItAdvanced {...props} />
                </div>
            )}
        </Form>
    );
};

export default TestItForm;
