import {
    faChevronLeft,
    faChevronRight,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReactPaginate from 'react-paginate';
import Button from '../../Components/Buttons/Button';
import Delete from '../../Components/Buttons/Delete';
import Edit from '../../Components/Buttons/Edit';
import Modal from '../../Components/Modal';
import Text from '../../Components/Text';
import TextDate from '../../Components/TextDate';
import { MessageContext } from '../../Context/MessageContext';
import Sublist from '../List/Sublist';

import style from '../../../../scss/components/list.module.scss';
import paginationStyle from '../../../../scss/components/paginator/paginator.module.scss';
import usePagination from '../../../Hooks/usePagination';
import TextResource from '../../Components/TextResource';

const UserReviews = ({ service, resource, entity: userEntity }) => {
    const { t } = useTranslation();
    const { pushMessage } = useContext(MessageContext);
    const [reviews, setReviews] = useState([]);
    const [deleteAllModal, showDeleteAllModal] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        currentPage,
        currentPageIndex,
        totalPages,
        changePage,
        updateTotalItems,
        itemsPerPage,
    } = usePagination({
        totalItemsCount: 0,
        itemsPerPage: 20,
    });

    const getReviews = () => {
        setIsLoading(() => true);
        service
            .get('reviews', {
                'filter[user_id]': userEntity.id,
                page: currentPage,
                per_page: itemsPerPage,
                'filter[type]': 'product',
            })
            .then((data) => {
                setReviews(data.data);
                updateTotalItems(data.meta.total);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getReviews();
    }, [currentPage]);

    const openDeleteAllModal = () => {
        showDeleteAllModal(true);
    };

    const closeDeleteAllModal = () => {
        showDeleteAllModal(false);
    };

    const handleDeleteAll = () => {
        service
            .remove('users/reviews', userEntity.id)
            .then(() => {
                setReviews([]);
                closeDeleteAllModal();
                pushMessage({ title: 'Sikeres mentés!' });
            })
            .catch(() => {
                setDeleteError('delete_error');
                pushMessage({
                    title: 'A szerkesztés meghiúsult!',
                    type: 'error',
                });
            });
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center space-y-4 mb-16">
                <TextResource
                    resource="reviews"
                    name="loading"
                    className="text-3xl mb-8"
                />
            </div>
        );
    }

    return (
        <div className="flex flex-col space-y-4 mb-16">
            <Sublist
                service={service}
                resource="reviews"
                entity={reviews}
                onDelete={getReviews}
            >
                <Edit name="id" />
                <Text name="product.name" isNested />
                <Text name="product.brand.title" isNested />
                <TextDate name="created_at" />
                <Text
                    name="description"
                    isParagraph
                    className="max-w-2xl truncate"
                />
                <Delete name="delete" list />
            </Sublist>

            <ReactPaginate
                forcePage={currentPageIndex}
                pageCount={totalPages}
                pageRangeDisplayed={4}
                marginPagesDisplayed={0}
                renderOnZeroPageCount={null}
                onPageChange={changePage}
                className={paginationStyle.paginationContainer}
                pageClassName={paginationStyle.paginationPage}
                activeClassName={paginationStyle.paginationPageActive}
                previousClassName={paginationStyle.paginationPage}
                nextClassName={paginationStyle.paginationPage}
                pageLinkClassName={paginationStyle.paginationPageLink}
                nextLinkClassName={paginationStyle.paginationPageLink}
                previousLinkClassName={paginationStyle.paginationPageLink}
                breakClassName="hidden"
                disabledClassName="opacity-40"
                previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
                nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
            />

            {reviews.length > 0 && (
                <Button
                    name="delete_all_reviews"
                    icon={faTrash}
                    click={openDeleteAllModal}
                    classNames="flex items-center justify-center font-semibold gap-4 bg-red py-5 px-10 rounded-lg text-white text-2xl bg-opacity-90 hover:bg-opacity-100 transition-colors"
                />
            )}

            <Modal
                contentLabel="Törlés megerősítése"
                isOpen={deleteAllModal}
                onRequestClose={closeDeleteAllModal}
            >
                <p>
                    {t('application.delete_all_reviews_confirm', {
                        reviewsCount: reviews.length,
                    })}
                </p>

                {!deleteError && (
                    <Button name="delete" click={handleDeleteAll}>
                        {t('application.delete_all_reviews_button')}
                    </Button>
                )}
                {deleteError && (
                    <div className={style.errorMessage}>
                        {t(`application.${deleteError}`, {
                            resource: t(`application.${resource}`),
                        })}
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default UserReviews;

UserReviews.propTypes = {
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of resource
     */
    resource: PropTypes.string.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
};
