import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from '../../Framework/Components/Form/Form';
import TextResource from '../../Framework/Components/TextResource';
import Grid, { GridLeft, GridRight } from '../../Framework/Layouts/Grid';
import ProductUrlText from '../Products/ProductUrlText';
import TextInput from '../../Framework/Components/Form/TextInput';
import BlockStyledEditor from '../../Framework/Components/Form/BlockStyledEditor';
import Toggle from '../../Framework/Components/Form/Toggle';
import Image from '../../Framework/Components/Image/Image';
import DateTime from '../../Framework/Components/Form/DateTime';
import StaticDropDown from '../../Framework/Components/Form/StaticDropDown';
import { TESTIT_PROJECTS_TYPES } from '../TestIt/TestItForm';
import FormDelete from '../../Framework/Components/Buttons/FormDelete';
import ProductOfferCommentTransfer from './ProductOfferProductTransfer';
import ReviewReplies from '../Reviews/ReviewReplies';
import Text from '../../Framework/Components/Text';

export const STATUS_TYPES = {
    AVAILABLE: 'AVAILABLE',
    RESERVED: 'RESERVED',
    CLOSED: 'CLOSED',
};

const ProductOfferForm = (props) => {
    const { t } = useTranslation();
    const statusOptions = [
        {
            id: STATUS_TYPES.AVAILABLE,
            label: t(
                `product-offers.status_key.${STATUS_TYPES.AVAILABLE}`,
                TESTIT_PROJECTS_TYPES.BIGGER,
            ),
        },
        {
            id: STATUS_TYPES.RESERVED,
            label: t(
                `product-offers.status_key.${STATUS_TYPES.RESERVED}`,
                TESTIT_PROJECTS_TYPES.SMALLER,
            ),
        },
        {
            id: STATUS_TYPES.CLOSED,
            label: t(
                `product-offers.status_key.${STATUS_TYPES.CLOSED}`,
                TESTIT_PROJECTS_TYPES.GIVE_AWAY,
            ),
        },
    ];

    return (
        <Form {...props}>
            <TextResource
                name="product-offer"
                className="text-3xl mb-8"
                isParagraph
                {...props}
            />

            <Grid classNames="mb-16">
                <GridLeft>
                    <Text
                        includeLabel
                        customLabel={t('product-offers.product_name')}
                        labelClassName="text-2xl"
                        name="product.name"
                        isNested
                        className="text-2xl pb-8 block"
                    />
                    <Text
                        name="created_by.username"
                        includeLabel
                        labelClassName="text-2xl"
                        isNested
                        className="text-2xl pb-8 block"
                    />

                    <ProductUrlText />
                    <TextInput name="name" />
                    <TextInput name="size" />
                    <BlockStyledEditor name="description" fullFeatureSet />
                    <TextInput name="price" />
                    <TextInput name="shipping_payment" />
                    <TextInput name="used" />
                    <TextInput name="status" />
                    <TextInput name="where_to_find" />
                </GridLeft>
                <GridRight>
                    <StaticDropDown
                        name="status"
                        staticOptions={statusOptions}
                    />
                    <DateTime name="created_at" readOnly />
                    <DateTime name="reserved_at" readOnly />
                    <DateTime name="bought_at" readOnly />
                    <DateTime name="expire_at" readOnly />

                    <Toggle name="is_sold" />
                    <Image name="images" limit={10} isDetachable />
                </GridRight>
            </Grid>
            <FormDelete />
            <ProductOfferCommentTransfer />
            <ReviewReplies commentResource="product-offer-comments" />
        </Form>
    );
};
export default ProductOfferForm;
