import React from 'react';
import List from '../../Framework/Pages/List/List';
import Edit from '../../Framework/Components/Buttons/Edit';
import Text from '../../Framework/Components/Text';
import TextDate from '../../Framework/Components/TextDate';
import CommentSearch from './CommentSearch';

const CommentsList = (props) => {
    return (
        <List {...props} search={CommentSearch} deleteonly>
            <Edit name="id" />
            <Text name="user.username" isNested />
            <TextDate name="created_at" />
            <Text name="product.name" isNested />
            <Text name="comment" isParagraph className="max-w-2xl truncate" />
            <TextDate name="created_at" />
        </List>
    );
};

export default CommentsList;
