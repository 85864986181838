export default {
    'product-change-requests': 'Product changes',
    resource: 'Product change',

    id: 'ID',
    name: 'Name',
    canonical_name: 'Canonical name',
    lead: 'Lead',
    slug: 'Slug',
    description: 'Description',
    image: 'Image',
    images: 'Images',
    price: 'Price',
    size: 'Package',
    where_to_find: 'Location',
    is_active: 'Active',
    is_sponsored: 'Sponsored',
    is_18_plus: 'over 18',
    eans: 'EANs',
    brand: 'Brand',
    primary_category: 'Primary Category',
    categories: 'Categories',
    skin_types: 'Skin types',
    skin_concerns: 'Skin problems',
    hair_problems: 'Hair problems',
    ingredients: 'Ingredients',
    tags: 'Tags',
    original: 'Change request',
    delete: 'Delete',
};
