import React from 'react';
import PropTypes from 'prop-types';
import style from '../../../scss/components/form.module.scss';
import buttonStyle from '../../../scss/components/buttons/button.module.scss';

const ProductUrlText = ({ entity }) => {
    if (!entity) return null;

    const primaryCategory = entity.primary_category?.slug;
    const firstProductCategory = entity.product_categories?.[0]?.slug;
    const firstCategoryInHierarchy = entity.product_categories?.[0]?.slug;
    const category =
        primaryCategory || firstProductCategory || firstCategoryInHierarchy;
    const { slug } = entity;

    if (category == null || slug == null) return null;
    const url = `${import.meta.env.VITE_FRONTEND_URL}/kremek/${slug}`;

    return (
        <div className={style.formGroup}>
            <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className={buttonStyle.basic}
            >
                Go to Product
            </a>
        </div>
    );
};

export default ProductUrlText;

ProductUrlText.propTypes = {
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
};
