import axios from 'axios';

import queryParams from '../Helpers/url';

import convertEntityObject from '../Helpers/storeApiCallFilter';
import { removeAccessCookies } from '../Helpers/token';
import axiosInstance from './axios';

const ApplicationService = {
    localLogin: async (payload) => {
        const { email, password } = payload;
        const response = await axiosInstance.post('/api/auth/login', {
            email,
            password,
        });
        return response.data;
    },

    user: async () => {
        const response = await axiosInstance.get('/api/user');
        return response.data;
    },

    login: async (token) => {
        const response = await axios.get('/admin/auth/login', {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    },

    logout: async () => {
        removeAccessCookies();
        const response = await axios.get('/admin/auth/logout');
        return response.data;
    },

    xsrf: () => axios.get('/sanctum/csrf-cookie'),

    list: async (resource, page, size, params = {}) => {
        const response = await axiosInstance.get(
            queryParams(`/admin/${resource}`, {
                ...params,
                page,
                ...(size != null && { per_page: size }),
            }),
        );
        return response.data;
    },

    find: (resource, id) =>
        axiosInstance.get(`/admin/${resource}/${id}`).then((r) => r.data),

    autocomplete: (resource, params = {}) =>
        axiosInstance
            .get(queryParams(`/admin/autocomplete/${resource}`, { ...params }))
            .then((r) => r.data),

    post: (resource, path, payload) =>
        axiosInstance
            .post(`/admin/${resource}/${path}`, payload)
            .then((r) => r.data),

    store: (resource, entity, id = false) => {
        const editedEntity = convertEntityObject(resource, entity);

        return id
            ? axiosInstance
                  .put(`/admin/${resource}/${id}`, editedEntity)
                  .then((r) => r.data)
            : axiosInstance
                  .post(`/admin/${resource}`, editedEntity)
                  .then((r) => r.data);
    },

    remove: (resource, id) =>
        axiosInstance.delete(`/admin/${resource}/${id}`).then((r) => r.data),

    get: (path, params = {}) =>
        axiosInstance
            .get(queryParams(`/admin/${path}`, { ...params }))
            .then((r) => r.data),

    approve: (resource, entity, changeId) =>
        axiosInstance
            .post(`/admin/${resource}/${changeId}/approve`, entity)
            .then((r) => r.data),

    reject: (resource, entity, changeId) =>
        axiosInstance
            .post(`/admin/${resource}/${changeId}/reject`, entity)
            .then((r) => r.data),
};

export default ApplicationService;
