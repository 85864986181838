import PropTypes from 'prop-types';
import React from 'react';

import Button from './Button';

// STYLES
import style from '../../../../scss/components/buttons/button.module.scss';

const Select = ({ entity, remove, ...props }) => (
    <Button classNames={style.select} click={() => remove(entity)} {...props} />
);

export default Select;

Select.propTypes = {
    /**
     * entity of remove
     */
    remove: PropTypes.func.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of props
     */
    props: PropTypes.array,
};

Select.defaultProps = {
    props: [],
};
