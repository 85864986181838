export default {
    headerPlaceholder: 'Fejléc',
    placeholder: 'Ide kerül a tartalom...',
    Alt: 'Alternatív szöveg',
    Caption: 'Felirat',
    Author: 'Szerző',
    Quote: 'Idézet',
    messages: {
        ui: {
            blockTunes: {
                toggler: {
                    'Click to tune': 'Beállítás',
                },
            },
            toolbar: {
                toolbox: {
                    Add: 'Hozzáadás',
                },
            },
            inlineToolbar: {
                converter: {
                    'Convert to': 'Átalakítás',
                },
            },
            popover: {
                Filter: 'Szűrő',
            },
        },
        toolNames: {
            Text: 'Szöveg',
            Heading: 'Címsor',
            List: 'Lista',
            Image: 'Kép',
            Quote: 'Idézet',
            Gallery: 'Galéria',
        },
        tools: {
            list: {
                Ordered: 'Ordered',
                Unordered: 'Unordered',
            },
            heading: {
                'Heading 1': 'Heading (H1)',
                'Heading 2': 'Heading (H2)',
                'Heading 3': 'Heading (H3)',
            },
            quote: {
                'Align Left': 'Align left',
                'Align Center': 'Alignment to the center',
            },
            image: {
                Alt: 'Alternative text',
                Caption: 'Subtitle',
            },
        },
        blockTunes: {
            delete: {
                Delete: 'Delete',
                'Click to delete': 'Click to delete',
            },
            moveUp: {
                'Move up': 'Up',
            },
            moveDown: {
                'Move down': 'Down',
            },
        },
    },
    gallery: {
        title: 'Gallery title',
        description: 'Description',
        imageUpload: 'Upload image(s)',
        delete: 'Delete',
        deleteAll: 'Delete All',
    },
};
