import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';

import TextResource from '../../Components/TextResource';
import usePagination from '../../../Hooks/usePagination';
import Sublist from '../List/Sublist';
import Edit from '../../Components/Buttons/Edit';
import Text from '../../Components/Text';
import Delete from '../../Components/Buttons/Delete';

import paginationStyle from '../../../../scss/components/paginator/paginator.module.scss';

const UserRatings = ({ service, entity: userEntity, userRole }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const {
        currentPage,
        currentPageIndex,
        totalPages,
        changePage,
        updateTotalItems,
        itemsPerPage,
    } = usePagination({
        totalItemsCount: 0,
        itemsPerPage: 10,
    });
    const getReviews = () => {
        setIsLoading(true);
        service
            .get('reviews', {
                per_page: itemsPerPage,
                page: currentPage,
                'filter[user_id]': userEntity.id,
                'filter[type]': `product-offer-${userRole}`,
            })
            .then((data) => {
                setReviews(data.data);
                updateTotalItems(data.meta.total);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getReviews();
    }, [currentPage]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center space-y-4 mb-16">
                <TextResource
                    resource="product-offers"
                    name="loading"
                    className="text-3xl mb-8"
                />
            </div>
        );
    }

    return (
        <div className="mb-8">
            <TextResource
                resource="users"
                name={`${userRole}_ratings`}
                className="text-2xl mb-8 font-medium"
                isParagraph
            />
            <Sublist
                service={service}
                resource="reviews"
                entity={reviews}
                onDelete={getReviews}
            >
                <Edit name="id" />
                <Text name="product.name" isNested />
                <Text name="rate" />
                <Text
                    name="description"
                    isParagraph
                    className="max-w-2xl truncate"
                />
                <Delete name="delete" list />
            </Sublist>
            <ReactPaginate
                forcePage={currentPageIndex}
                pageCount={totalPages}
                pageRangeDisplayed={4}
                marginPagesDisplayed={0}
                renderOnZeroPageCount={null}
                onPageChange={changePage}
                className={paginationStyle.paginationContainer}
                pageClassName={paginationStyle.paginationPage}
                activeClassName={paginationStyle.paginationPageActive}
                previousClassName={paginationStyle.paginationPage}
                nextClassName={paginationStyle.paginationPage}
                pageLinkClassName={paginationStyle.paginationPageLink}
                nextLinkClassName={paginationStyle.paginationPageLink}
                previousLinkClassName={paginationStyle.paginationPageLink}
                breakClassName="hidden"
                disabledClassName="opacity-40"
                previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
                nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
            />
        </div>
    );
};

export default UserRatings;

UserRatings.propTypes = {
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of userRole
     */
    userRole: PropTypes.oneOf(['seller', 'buyer']).isRequired,
};
