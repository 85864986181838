import PropTypes from 'prop-types';
import React from 'react';

// COMPONENTS
import Field from './Field';

// STYLES
import style from '../../../../scss/components/form.module.scss';

const TextInput = ({
    onChange,
    entity,
    regex = null,
    defaultValue = '',
    ...props
}) => {
    // SIDE EFFECTS
    const change = (e) =>
        onChange({
            [props.name]: regex
                ? e.target.value.replace(regex, '')
                : e.target.value,
        });

    // RENDER
    return (
        <div className={style.formGroup}>
            <Field {...props}>
                {(hasError, attributes, isChanged) => (
                    <input
                        className={`${style.formTextInput} ${
                            hasError ? style.isInvalid : ''
                        } ${isChanged ? style.changedInput : ''}
                        `}
                        type="text"
                        onChange={change}
                        value={entity[props.name] || defaultValue}
                        autoComplete="off"
                        {...attributes}
                    />
                )}
            </Field>
        </div>
    );
};

export default TextInput;

TextInput.propTypes = {
    /**
     * Type of onChange
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of regex
     */
    regex: PropTypes.instanceOf(RegExp),
    /**
     * Type of name
     */
    name: PropTypes.string.isRequired,
    /**
     * Type of changedFields
     */
    changedFields: PropTypes.array,
    /**
     * Type of defaultValue
     */
    defaultValue: PropTypes.string,
};

TextInput.defaultProps = {
    regex: null,
    changedFields: [],
    defaultValue: '',
};
