import React, { useState } from 'react';
import PropTypes from 'prop-types';

// TRANSLATIONS
import { useTranslation } from 'react-i18next';

// ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// COMPONENTS
import Modal from '../Modal';
import Button from './Button';

// STYLES
import style from '../../../../scss/components/buttons/button.module.scss';
import listStyle from '../../../../scss/components/list.module.scss';

const FormDelete = ({ entity, service, resource }) => {
    const { t } = useTranslation();
    const [marked, setMarked] = useState(null);
    const clearMark = () => {
        setMarked(null);
    };
    const [deleteError, setDeleteError] = useState(false);

    const removeEntity = () =>
        service
            .remove(resource, entity.id)
            .then(() => setMarked(null))
            .then(() => window.history.back())
            .catch((error) => {
                if (error.response) {
                    setDeleteError(error.response.data.message);
                }
            });
    return (
        <>
            <button
                className={style.delete}
                type="button"
                onClick={() => setMarked(true)}
            >
                <span className={style.formDeleteText}>
                    {t('application.delete')}
                </span>
                <FontAwesomeIcon className={style.rejectIcon} icon={faTrash} />
            </button>
            <Modal
                contentLabel="Törlés megerősítése"
                isOpen={!!marked}
                onRequestClose={clearMark}
            >
                {marked && (
                    <p>
                        Biztosan törlöd a következőt:{' '}
                        <strong>
                            {marked.name || marked.title || marked.username}
                        </strong>
                        ?
                    </p>
                )}
                {!deleteError && (
                    <Button name="delete" click={removeEntity}>
                        Törlés
                    </Button>
                )}
                {deleteError && (
                    <div className={listStyle.errorMessage}>
                        {t(`application.${deleteError}`, {
                            resource: t(`application.${resource}`),
                        })}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default FormDelete;

FormDelete.propTypes = {
    /**
     * entity of remove
     */
    service: PropTypes.func.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,

    resource: PropTypes.string.isRequired,
};
