import React from 'react';
import Delete from '../../Framework/Components/Buttons/Delete';
import Edit from '../../Framework/Components/Buttons/Edit';
import Text from '../../Framework/Components/Text';
import List from '../../Framework/Pages/List/List';
import UserSearch from './UserSearch';

const UserList = (props) => {
    return (
        <List search={UserSearch} {...props} deleteonly>
            <Text name="id" />
            <Edit name="username" />
            <Text name="email" />
            <Text name="firstname" />
            <Text name="lastname" />
            <Delete name="delete" list />
        </List>
    );
};

export default UserList;
