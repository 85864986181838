import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import getNestedValue from '../../Helpers/nestedValue';

const Text = ({
    entity,
    name,
    isParagraph = false,
    isNested = false,
    isCount = false,
    includeLabel = false,
    localized = false,
    customResource = null,
    resource,
    customLabel,
    labelClassName,
    ...props
}) => {
    const { t } = useTranslation();

    if (!entity) return null;

    const Paragraph = isParagraph ? 'p' : 'span';
    let content = isNested ? getNestedValue(entity, name) : entity?.[name];
    if (isCount) {
        content = entity?.[name]?.length;
    }

    if (localized) {
        const currentResource = customResource ?? resource;
        content = t(`${currentResource}.${content}`, content);
    }

    const label = customLabel || t(`${resource}.${name}`);
    if (includeLabel) {
        return (
            <div className="flex flex-col">
                <span className={labelClassName}>{label}</span>
                <Paragraph {...props}>{content ?? '-'}</Paragraph>
            </div>
        );
    }

    return <Paragraph {...props}>{content ?? '-'}</Paragraph>;
};

export default Text;

Text.propTypes = {
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of name
     */
    name: PropTypes.string.isRequired,
    /**
     * Type of name
     */
    isParagraph: PropTypes.bool,
    /**
     * Type of name
     */
    isNested: PropTypes.bool,
    /**
     * Type of isCount
     */
    isCount: PropTypes.bool,
    /**
     * Type of includeLabel
     */
    includeLabel: PropTypes.bool,
    /**
     * Type of localized
     */
    localized: PropTypes.bool,
    /**
     * Type of resource
     */
    resource: PropTypes.string.isRequired,
    /**
     * Type of customResource
     */
    customResource: PropTypes.string,
    /**
     * Type of customLabel
     */
    customLabel: PropTypes.string,
    /**
     * Type of labelClassName
     */
    labelClassName: PropTypes.string,
};

Text.defaultProps = {
    isParagraph: false,
    isNested: false,
    isCount: false,
    includeLabel: false,
    localized: false,
    customResource: null,
    customLabel: '',
    labelClassName: '',
};
