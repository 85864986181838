export default {
    users: 'Users',
    resource: 'User',

    id: 'ID',
    name: 'Name',
    email: 'Email',
    firstname: 'First name',
    lastname: 'Last name',
    username: 'Username',
    birth_year: 'Year of birth',
    'skin_type.name': 'Skin Type',
    'skin_concern.name': 'Skin problem',

    created_at: 'Registered',
    image: 'Image',

    points: 'Points',
    uuid: 'UUID',
    title: 'Title',
    sso_id: 'SSO ID',
    gender: 'Gender',
    'hair_problem.name': 'Hair problem',
    filled_questionnaire_at: 'Filled questionnaire at',
    description: 'Description',

    'activities.ip_address': 'IP address',
    delete: 'Delete',

    // User Form Sections headins
    profile: 'Profile Details',
    reviews: 'Reviews',

    banned: 'This user is banned from Marketplace until {{date}}',

    marketplace_activities: 'Marketplace Activities',
    seller: 'Selling / Sold Product Offers',
    buyer: 'Bought Product Offers',

    user_ratings: 'User Ratings',
    seller_ratings: 'Ratings on Sold Items',
    buyer_ratings: 'Ratings on Purchased Items',
};
