export default {
    product_name: 'Product name',
    'product-offers': 'Marketplace',
    resource: 'Product Offer',

    // Search
    published_from: 'Published from',
    published_to: 'Published to',
    sold_from: 'Sold from',
    sold_to: 'Sold to',
    status_options: 'Status',
    'user.username': 'Username',
    filter_search: 'Product name',

    // List
    delete: 'Delete',
    status: 'Status',
    'bought_by.username': 'Bought by',
    is_sold: 'Sold',

    // Form
    product: 'Product',
    images: 'Images',
    where_to_find: 'Where to find',

    id: 'ID',
    size: 'Size',
    description: 'Description',
    price: 'Price',
    shipping_payment: 'Delivery',
    created_at: 'Created at',
    used: 'Condition',
    reserved_at: 'Reserved at',
    bought_at: 'Booked at',
    'created_by.username': 'Created by',
    'reserved_by.username': 'Reserved by',
    expire_at: 'Expire at',

    'product-offer': 'Product Offer Detail',
    name: 'Name',
    category: 'Category',

    // types of status
    'status_key.AVAILABLE': 'Available',
    'status_key.RESERVED': 'Reserved',
    'status_key.CLOSED': 'Closed',
    'filter.status': 'Status',

    // Transfer
    product_name_label: 'Product Name',
    product_name_placeholder: 'Enter product name',
    transfer_or_label: 'or',
    product_id_label: 'Product ID',
    product_id_placeholder: 'Enter product ID',
    'application.move': 'Move',
    loading: 'Loading',
};
