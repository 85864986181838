import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import getNestedValue from '../../Helpers/nestedValue';

const ArrayCheckboxes = ({
    resource,
    entity,
    name,
    includeLabel,
    isNested,
}) => {
    const { t } = useTranslation();

    if (!entity) return null;
    let content = isNested ? getNestedValue(entity, name) : entity?.[name];

    try {
        content = JSON.parse(content);
        if (!content || !Array.isArray(content)) throw new Error();
    } catch (e) {
        return null;
    }

    return (
        <div className="flex flex-col">
            {includeLabel && <span>{t(`${resource}.${name}`)}</span>}

            {content.map((item) => (
                <div key={item} className="flex items-center">
                    <input
                        id={`issue-${item}`}
                        type="checkbox"
                        value={item}
                        checked
                        disabled
                    />
                    <label htmlFor={`issue-${item}`}>{item}</label>
                </div>
            ))}
        </div>
    );
};

export default ArrayCheckboxes;

ArrayCheckboxes.propTypes = {
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
    /**
     * Type of name
     */
    name: PropTypes.string.isRequired,
    /**
     * Type of includeLabel
     */
    includeLabel: PropTypes.bool,
    /**
     * Type of isNested
     */
    isNested: PropTypes.bool,
    /**
     * Type of resource
     */
    resource: PropTypes.string,
};

ArrayCheckboxes.defaultProps = {
    includeLabel: false,
    resource: null,
    isNested: false,
};
