import React from 'react';
import { useTranslation } from 'react-i18next';
import Search from '../../Framework/Components/Search';
import Text from '../../Framework/Components/Form/TextInput';
import StaticDropDown from '../../Framework/Components/Form/StaticDropDown';
import { TESTIT_PROJECTS_TYPES } from '../TestIt/TestItForm';
import { STATUS_TYPES } from './ProductOfferForm';
import DateTime from '../../Framework/Components/Form/DateTime';

const ProductOfferSearch = (props) => {
    const { t } = useTranslation();
    const statusOptions = [
        {
            id: STATUS_TYPES.AVAILABLE,
            label: t(
                `product-offers.status_key.${STATUS_TYPES.AVAILABLE}`,
                TESTIT_PROJECTS_TYPES.BIGGER,
            ),
        },
        {
            id: STATUS_TYPES.RESERVED,
            label: t(
                `product-offers.status_key.${STATUS_TYPES.RESERVED}`,
                TESTIT_PROJECTS_TYPES.SMALLER,
            ),
        },
        {
            id: STATUS_TYPES.CLOSED,
            label: t(
                `product-offers.status_key.${STATUS_TYPES.CLOSED}`,
                TESTIT_PROJECTS_TYPES.GIVE_AWAY,
            ),
        },
    ];
    return (
        <Search {...props}>
            <Text name="user.username" />
            <Text name="filter_search" />
            <DateTime name="published_from" />
            <DateTime name="published_to" />
            <DateTime name="sold_from" />
            <DateTime name="sold_to" />
            <StaticDropDown
                name="filter.status"
                staticOptions={statusOptions}
            />
        </Search>
    );
};

export default ProductOfferSearch;
