import axios from 'axios';
import { getAccessToken } from '../Helpers/token';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = await getAccessToken();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error),
);

export default axiosInstance;
