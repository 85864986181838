import React from 'react';
import Edit from '../../Framework/Components/Buttons/Edit';
import Text from '../../Framework/Components/Text';
import TextDate from '../../Framework/Components/TextDate';
import List from '../../Framework/Pages/List/List';

const ReportsList = (props) => {
    return (
        <List {...props} deleteonly>
            <Edit name="id" />
            <Edit name="product_name" />
            <Text name="username" />
            <Text name="type" localized customResource="reports.type_key" />
            <TextDate name="created_at" />
        </List>
    );
};

export default ReportsList;
