export default {
    'ingredient-change-requests': 'Ingredient changes',
    resource: 'Ingredient change',

    id: 'ID',
    name: 'Name',
    description: 'Description',
    ewg_data: 'EWG data',
    ewg_score: 'EWG risk',
    ewg_score_max: 'EWG max risk',
    comedogen_index: 'Comedogen index',
    categories: 'Functions',

    Robust: 'Very much',
    Good: 'Many',
    Fair: 'Medium',
    Limited: 'Limited',
    None: 'None',
};
