export default {
    products: 'Products',
    resource: 'Product',

    id: 'ID',
    name: 'Name',
    category: 'Category',
    published_from: 'Published from',
    published_to: 'Published to',
    canonical_name: 'Canonical name',
    image: 'Image',
    images: 'Images',
    lead: 'Lead',
    slug: 'Slug',
    description: 'Description',
    price: 'Price',
    size: 'Package',
    where_to_find: 'Location',
    is_active: 'Active',
    is_sponsored: 'Sponsored',
    is_18_plus: 'over 18',
    published_at: 'Published',

    tags: 'Tags',
    brand: 'Brand',
    primary_category: 'Primary Category',
    product_categories: 'Categories',
    skin_types: 'Skin Type',
    skin_concerns: 'Skin problems',
    hair_problems: 'Hair problems',
    ingredients: 'Ingredients',
    eans: 'EANs',
    sponsored_url: 'Sponsored url',

    delete: 'Delete',

    // Sections
    product: 'Product Details',
    reviews: 'Reviews',

    // Search
    is_hot: 'Hot',
    is_manufactured: 'Is Manufactured',
    sort_by: 'Sort by',
    'sortby.reviews_most': 'Most reviews',
    'sortby.reviews_least': 'Least reviews',
    'sortby.reviews_average_high': 'Average rating (High to Low)',
    'sortby.reviews_average_low': 'Average rating (Low to High)',
    'sortby.published_date_newest': 'Newest',
    'sortby.published_date_oldest': 'Oldest',

    // Transfer Reviews
    select: 'Transfer to',
    copy_to: 'Copy to',
};
